import axios from "axios";

import ApiBase from "./api-base";

export default class CommentApi extends ApiBase {
  constructor() {
    super("comment");
  }

  getLatest = () => {
    const url = `${this.baseApiUrl}/GetLatests`;
    const response = axios.post(url);
    return response;
  };

  getComments = (contentType, contentId, page = 0) => {
    const url = `${this.baseApiUrl}/Comments/${contentType}/${contentId}/${page}`;
    const response = axios.get(url);
    return response;
  };
}
