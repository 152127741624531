import React, {
  useEffect,
  useState,
} from 'react';

import { useParams } from 'react-router-dom';
import DocumentContentApi from 'src/api/document-content-api';

import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';

function DocumentContentDetail() {
  const [data, setData] = useState(null);
  let { id } = useParams();

  useEffect(() => {
    if (!id) return;
    new DocumentContentApi().get(id).then((response) => {
      setData(response.data);
    });
  }, [id]);

  return (
    <>
      {data && (
        <>
          <h3>Tình huống</h3>
          <Grid
            container
            alignContent={"center"}
            alignItems={"center"}
            spacing={6}
          >
            <Grid item md={12}>
              <Typography color="text.secondary" gutterBottom>
                {data.code} {data.name}
              </Typography>
              <Typography component="div">
                <div dangerouslySetInnerHTML={{ __html: data.shortContent }} />
              </Typography>
            </Grid>
            <Grid item md={12}>
              {data.otherDocumentContents.map((item) => (
                <div>
                  <a href={`/documentcontent/${item.id}`}>{item.name}</a>
                </div>
              ))}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

export default DocumentContentDetail;
