import {
  useEffect,
  useState,
} from 'react';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import UserApi from 'src/api/user-api';
import ENV from 'src/config/ENV';
import authService from 'src/services/authService';
import { selectAccount } from 'src/store/slices/accountSlice';

function Login() {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    async function checkIfAccessTokenExists() {
      const params = new URLSearchParams(window.location.search);
      const token = params.get("token");
      if (token != null) {
        authService.setSession(token);
        new UserApi().me().then((response) => {
          dispatch(selectAccount(response.data));
          window.localStorage.setItem(
            "userData",
            JSON.stringify(response.data)
          );
          history.push("/");
        });
      }
    }
    checkIfAccessTokenExists();
  }, []);

  const loginWithGoogle = () => {
    var domain = `${ENV.basePortalUrl}`;
    var redirectUri = `${ENV.spireUrl}` + "login";
    var externalProviderUrl =
      domain +
      "/Account/ExternalLogin?provider=Google&response_type=token&redirect_uri=" +
      redirectUri;
    window.location.href = externalProviderUrl;
  };

  const loginWithFacebook = () => {
    var domain = `${ENV.basePortalUrl}`;
    var redirectUri = `${ENV.spireUrl}` + "login";
    var externalProviderUrl =
      domain +
      "/Account/ExternalLogin?provider=Facebook&response_type=token&redirect_uri=" +
      redirectUri;
    window.location.href = externalProviderUrl;
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12" style={{ marginTop: 60, marginBottom: 160 }}>
          <div style={{ maxWidth: 400, margin: "0 auto" }}>
            <ul className="list-group">
              <li
                className="list-group-item"
                style={{ textAlign: "center", padding: 10 }}
              >
                <b style={{ margin: 0, padding: 0, fontSize: 20 }}>Đăng nhập</b>
              </li>
              <li className="list-group-item" style={{ padding: 20, textAlign: 'center' }}>
                <a
                  className="btn btn-primary btn-lg btn-block"
                  onClick={(e) => {
                    loginWithGoogle();
                    e.preventDefault();
                  }}
                  href="javascript:void(0)"
                >
                  <i className="fa fa-google"></i>&nbsp;&nbsp;Đăng nhập bằng Google
                </a>
              </li>
              <li className="list-group-item" style={{ padding: 20, textAlign: 'center' }}>
                <a
                  className="btn btn-primary  btn-lg btn-block"
                  href="javascript:void(0)"
                  onClick={(e) => {
                    loginWithFacebook();
                    e.preventDefault();
                  }}
                >
                  <i className="fa fa-facebook"></i>&nbsp;&nbsp;Đăng nhập bằng Facebook
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
