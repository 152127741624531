import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import NewsApi from "src/api/news-api";
import { useSelector } from "react-redux";
import { selectedAccount } from "src/store/slices/accountSlice";
import UserProfileApi from "src/api/user-profile-api";
import { Link } from "react-router-dom";
import CommentApi from "src/api/comment-api";
import ENV from "src/config/ENV";
import Typography from "@mui/material/Typography";
import moment from "moment";
import {
  DocumentFieldCatalogApi,
  DocumentSubjectCatalogApi,
} from "src/api/catalog-api";
import InteresetCatalogs from "src/components/interest-catalogs";
import AddComment from "src/components/comments/add";
import Comments from "src/components/comments/list";
import LastestComments from "src/components/comments/lastest";

function NewsDetail() {
  const [data, setData] = useState(null);
  const [profile, setProfile] = useState(null);
  const currentUser = useSelector(selectedAccount);
  const [comments, setComments] = useState(null);
  const [featureNews, setFeatureNews] = useState(null);
  const [otherNews, setOtherNews] = useState(null);
  const [subjectCatalogs, setSubjectCatalogs] = useState([]);
  const [fieldCatalogs, setFieldCatalogs] = useState([]);

  let { id } = useParams();

  useEffect(() => {
    if (!id) return;
    new NewsApi().get(id).then((response) => {
      setData(response.data.value);

      if (response.data.value) {
        new NewsApi()
          .getOtherNews(response.data.value.catalogId, response.data.value.id)
          .then((response) => {
            setOtherNews(response.data.data);
          });
      }
    });

    new CommentApi().getLatest().then((response) => {
      setComments(response.data.data);
    });

    new NewsApi().getFeatureNews().then((response) => {
      setFeatureNews(response.data.data);
    });
  }, [id]);

  useEffect(() => {
    if (currentUser && currentUser.id) {
      const api = new UserProfileApi();
      api.me().then((response) => {
        setProfile(response.data.value);
      });
    } else {
      DocumentSubjectCatalogApi.searches(null).then((response) => {
        setSubjectCatalogs(response.data.data);
      });

      DocumentFieldCatalogApi.searches(null).then((response) => {
        setFieldCatalogs(response.data.data);
      });
    }
  }, [currentUser]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  return (
    <>
      {data && (
        <>
          <div className="container">
            <nav aria-label="breadcrumb" className="">
              <ol className="breadcrumb bc-edit my-4">
                <li className="breadcrumb-item">
                  <Link to="/" className="icon-home">
                    <img src="/theme/img/icon-home.svg" />
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  {/* to={`/newscategory/${data.catalogId}`} */}
                  <Link to={`/`}>{data.catalogName}</Link>
                </li>
                {/* <li className="breadcrumb-item active" aria-current="page">
                  {data.name}
                </li> */}
              </ol>
            </nav>
          </div>
          <div className="main-news">
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  <div className="box-posts">
                    <div className="bp-title">
                      <h2>{data.name}</h2>

                      <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills1">
                          <div className="bp-content">
                            <div
                              dangerouslySetInnerHTML={{ __html: data.content }}
                            />
                            <div className="bpc-author">
                              <ul>
                                <li>
                                  <a href="">
                                    <img src="/theme/img/icon-share.svg" />
                                    Chia sẻ
                                  </a>
                                </li>
                                <li>
                                  <a href="">
                                    <img src="/theme/img/icon-print.svg" />
                                    In bài viết
                                  </a>
                                </li>
                                <li>
                                  <a href="">
                                    <img src="/theme/img/icon-save.svg" />
                                    Lưu bài viết
                                  </a>
                                </li>
                              </ul>
                              <label>
                                Tác giả: &nbsp;<span> {data.author}</span>
                              </label>
                            </div>
                          </div>
                          {otherNews && (
                            <div className="box-detail">
                              <div className="main-title">
                                <h2>Bài viết liên quan</h2>
                              </div>
                              <div className="bd-relate">
                                <div className="row">
                                  {otherNews.map((item) => (
                                    <div className="col-sm-6">
                                      <Link
                                        to={`/news/${item.id}`}
                                        className="ln-detail"
                                      >
                                        <span>
                                          <img
                                            src={`${ENV.baseFileImageUrl}${item.thumbnailUrl}`}
                                            style={{
                                              borderRadius: 10,
                                            }}
                                          />
                                        </span>
                                        <article>
                                          <label style={{ cursor: "pointer" }}>
                                            {item.name}
                                          </label>
                                          <p className="lnd-tag">
                                            {item.catalogName}
                                          </p>
                                          <ul className="lnd-view">
                                            <li>
                                              <img src="/theme/img/icon-calenda.svg" />{" "}
                                              {moment(item.createdTime).format(
                                                "DD/MM/YYYY"
                                              )}
                                            </li>
                                            {/* <li>
                                            <img src="/theme/img/icon-view.svg" />{" "}
                                            1000
                                          </li>
                                          <li>
                                            <img src="/theme/img/icon-comment.svg" />{" "}
                                            10
                                          </li> */}
                                          </ul>
                                        </article>
                                      </Link>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          )}

                          <Comments />

                          <AddComment />
                        </div>
                        <div className="tab-pane fade" id="pills2">
                          .2..
                        </div>
                        <div className="tab-pane fade" id="pills3">
                          .3..
                        </div>
                        <div className="tab-pane fade" id="pills4">
                          .4..
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <InteresetCatalogs
                    profile={profile}
                    subjectCatalogs={subjectCatalogs}
                    fieldCatalogs={fieldCatalogs}
                  />

                  {featureNews && (
                    <div className="box-news bn-news-hot">
                      <label>Tin nổi bật</label>
                      <ul>
                        {featureNews.map((item) => (
                          <li>
                            <a href="" className="ln-detail">
                              <span>
                                <img
                                  src={
                                    item.thumbnailUrl != ""
                                      ? `${ENV.baseFileImageUrl}${item.thumbnailUrl}`
                                      : "/theme/img/no-img-1.jpg"
                                  }
                                  style={{
                                    borderRadius: 10,
                                  }}
                                />
                              </span>
                              <article>
                                <label>{item.name}</label>
                                <p className="lnd-tag">{item.catalogName}</p>
                                <ul className="lnd-view">
                                  <li>
                                    <img src="/theme/img/icon-calenda.svg" />{" "}
                                    {moment(item.createdTime).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </li>
                                  {/* <li>
                                  <img src="/theme/img/icon-view.svg" /> 1000
                                </li>
                                <li>
                                  <img src="/theme/img/icon-comment.svg" /> 10
                                </li> */}
                                </ul>
                              </article>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  <LastestComments data={comments} />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default NewsDetail;
