import ApiBase from "./api-base";
import axios from "axios";

export default class NewsApi extends ApiBase {
  constructor() {
    super("news");
  }

  getFeatureNews = () => {
    const url = `${this.baseApiUrl}/FeatureNews`;
    const response = axios.get(url);
    return response;
  };

  getOtherNews = (catalogId, newsId) => {
    const url = `${this.baseApiUrl}/GetOtherNews/${catalogId}/${newsId}`;
    const response = axios.get(url);
    return response;
  };
}
