import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Paper,
  Button,
  Input,
  Box,
  FormControlLabel,
  Checkbox,
  Grid,
  Drawer,
  IconButton,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import SearchIcon from "@mui/icons-material/Search";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DocumentQuery from "src/interfaces/Query/DocumentQuery";
import { XCircle as XIcon } from "react-feather";
import {
  DocumentFieldCatalogApi,
  DocumentSubjectCatalogApi,
  ServiceCatalogApi,
  DocumentSignerCatalogApi,
} from "src/api/catalog-api";
import UserContentTag from "../../../tag";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import DepartmentApi from "src/api/department-api";

function Filter({ onClose, onSearch, open }) {
  const [value, setValue] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [subjects, setSubjects] = useState(null);
  const [fields, setFields] = useState(null);
  const [fieldId, setFieldId] = useState(0);
  const [subjectId, setSubjectId] = useState(0);
  const [status, setStatus] = useState(-1);
  const [serviceProviders, setServiceProviders] = useState(null);
  const [departments, setDepartments] = useState(null);

  const [tags, setTags] = useState([]);
  const [age, setAge] = React.useState("");
  const [language, setLanguage] = React.useState(0);
  const [validStatus, setValidStatus] = React.useState(0);
  const [documentSignerCatalogs, setDocumentSignerCatalogs] = useState(null);
  const [signerId, setSignerId] = useState(0);
  const [type, setType] = useState(0);
  const [departmentId, setDepartmentId] = useState(0);
  const [mode, setMode] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [keyword, setKeyword] = useState(null);

  useEffect(() => {
    DocumentFieldCatalogApi.searches(null).then((response) => {
      setFields(response.data.data);
    });

    DocumentSubjectCatalogApi.searches(null).then((response) => {
      setSubjects(response.data.data);
    });

    DocumentSignerCatalogApi.searches(null).then((response) => {
      setDocumentSignerCatalogs(response.data.data);
    });

    new DepartmentApi().getAll().then((response) => {
      setDepartments(response.data.data);
    });
  }, []);

  const handleSearch = () => {
    var query = {
      Keyword: keyword,
      FieldId: fieldId,
      SubjectId: subjectId,
      Status: status,
      DepartmentId: departmentId,
    };
    if (onSearch) {
      onSearch(query);
    }
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      <Drawer
        anchor="right"
        ModalProps={{ BackdropProps: { invisible: true } }}
        onClose={handleClose}
        open={open}
        variant="temporary"
      >
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          <Box p={3} style={{ width: 400, paddingTop: 10 }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" color="textPrimary">
                Tìm kiếm nâng cao
              </Typography>
              <IconButton onClick={handleClose}>
                <SvgIcon fontSize="small">
                  <XIcon />
                </SvgIcon>
              </IconButton>
            </Box>
            <form style={{ width: "100%" }}>
              <RadioGroup>
                <FormControlLabel
                  value="female"
                  control={<Radio />}
                  label="Tất cả"
                />
                <FormControlLabel
                  value="male"
                  control={<Radio />}
                  label="Tiêu đề"
                />
                <FormControlLabel
                  value="other"
                  control={<Radio />}
                  label="  Số hiệu văn bản/ tài liệu"
                />
              </RadioGroup>
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Cụm từ chính xác"
              />
              <div className="mb-3">
                <br />
                <TextField
                  fullWidth
                  size="small"
                  label="Từ khóa"
                  onChange={(e) => setKeyword(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <UserContentTag onChange={(value) => setTags(value)} />
              </div>
              <div className="mb-3">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Ngày hiệu lực từ"
                    fullWidth
                    slotProps={{
                      textField: { size: "small", fullWidth: true },
                    }}
                    inputFormat="DD/MM/YYYY"
                    onChange={(value) => setStartDate(value)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
              <div className="mb-3">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    fullWidth
                    slotProps={{
                      textField: { size: "small", fullWidth: true },
                    }}
                    label="Ngày hiệu lực tới"
                    inputFormat="DD/MM/YYYY"
                    onChange={(value) => setEndDate(value)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
              <div className="mb-3">
                <FormControl size="small" fullWidth>
                  <InputLabel>Kiểu hiển thị</InputLabel>
                  <Select
                    value={type}
                    label="Kiểu hiển thị"
                    onChange={(e) => setType(parseInt(e.target.value, 10))}
                  >
                    <MenuItem value={0}>Tình huống</MenuItem>
                    <MenuItem value={1}>Văn bản, tài liệu</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="mb-3">
                <FormControl size="small" fullWidth>
                  <InputLabel>Đơn vị cung cấp</InputLabel>
                  <Select
                    value={departmentId}
                    label="Đơn vị cung cấp"
                    onChange={(e) =>
                      setDepartmentId(parseInt(e.target.value, 10))
                    }
                  >
                    <MenuItem value={0}>Tất cả</MenuItem>
                    {departments &&
                      departments.map((item) => (
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
              <div className="mb-3">
                <FormControl size="small" fullWidth>
                  <InputLabel>Tình trạng hiệu lực</InputLabel>
                  <Select
                    value={validStatus}
                    label="Tình trạng hiệu lực"
                    onChange={(e) =>
                      setValidStatus(parseInt(e.target.value, 10))
                    }
                  >
                    <MenuItem value={0}>Tất cả</MenuItem>
                    <MenuItem value={1}>Còn hiệu lực</MenuItem>
                    <MenuItem value={2}>Hết hiệu lực một phần</MenuItem>
                    <MenuItem value={3}>Hết hiệu lực</MenuItem>
                    <MenuItem value={4}>Chưa có hiệu lực</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="mb-3">
                <FormControl size="small" fullWidth>
                  <InputLabel>Địa bàn tác động</InputLabel>
                  <Select
                    value={fieldId}
                    label="Địa bàn tác động"
                    onChange={(e) => setFieldId(parseInt(e.target.value))}
                  >
                    <MenuItem value={0}>Tất cả</MenuItem>
                    {/* {fields &&
                      fields.map((item) => (
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                      ))} */}
                  </Select>
                </FormControl>
              </div>
              <div className="mb-3">
                <FormControl size="small" fullWidth>
                  <InputLabel>Ngôn ngữ</InputLabel>
                  <Select
                    value={language}
                    label="Ngôn ngữ"
                    onChange={(e) => setLanguage(parseInt(e.target.value, 10))}
                  >
                    <MenuItem value={0}>Tiếng Việt</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="mb-3">
                <FormControl size="small" fullWidth>
                  <InputLabel>Người ký/ Tác giả</InputLabel>
                  <Select
                    value={signerId}
                    label="Người ký/ Tác giả"
                    onChange={(e) => setSignerId(parseInt(e.target.value))}
                  >
                    <MenuItem value={0}>Tất cả</MenuItem>
                    {documentSignerCatalogs &&
                      documentSignerCatalogs.map((item) => (
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            </form>
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button
                color="secondary"
                variant="contained"
                onClick={handleSearch}
              >
                Tìm kiếm
              </Button>
            </Box>
          </Box>
        </PerfectScrollbar>
      </Drawer>
    </>
  );
}

Filter.propTypes = {
  className: PropTypes.string,
  onSearch: PropTypes.func,
};

export default Filter;
