import axios from "axios";

import ApiBase from "./api-base";

export default class UserApi extends ApiBase {
  constructor() {
    super("user");
  }

  me = () => {
    const url = `${this.baseApiUrl}/Me`;
    const response = axios.get(url);
    return response;
  };

  logout = () => {
    const url = `${this.baseApiUrl}/Logout`;
    const response = axios.get(url);
    return response;
  };
}
