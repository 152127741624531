import axios from 'axios';
import authService from 'src/services/authService';

class Interceptor {
    initialize = (store: any) => {

        // Add a response interceptor
        axios.interceptors.response.use(
            (response): any => {
                return response;
            },
            (error): any => {
                // error.message = error.response.data.Message || error.response.data || error.message;
                if (error.response != null && error.response.status === 401) {
                    error.message = 'Unauthorized';
                    //   store.dispatch(ApplicationAction.setAuthorized(false));
                }
                return Promise.reject(error);
            }
        );

        // Add a request interceptor
        axios.interceptors.request.use(
            config => {
                const token = authService.getAccessToken();
                if (token) {
                    config.headers['Authorization'] = 'Bearer ' + token;
                }
                return config;
            },
            error => {
                Promise.reject(error)
            });
    };
}

export default Interceptor;
