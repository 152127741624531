import React, { useEffect } from "react";

import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import UserNotificationApi from "src/api/user-notification-api";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function NotificationDetail({ id, onClose }) {
  const [data, setData] = React.useState(null);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    if (id == 0) return;
    const api = new UserNotificationApi();
    api.get(id).then((response) => {
      setData(response.data.value);
      api.read(id).then((reponse) => {});
    });
  }, [id]);

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={true}
    >
      <DialogTitle
        sx={{ m: 0, p: 2, fontSize: 15 }}
        id="customized-dialog-title"
      >
        Xem chi tiết thông báo
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      {data && (
        <DialogContent dividers>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Tiêu đề</TableCell>
                <TableCell>
                  <div> {data.name}</div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Mô tả</TableCell>
                <TableCell>
                  <div> {data.shortContent}</div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Nội dung</TableCell>
                <TableCell>
                  <div dangerouslySetInnerHTML={{ __html: data.content }} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
      )}
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Đóng
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
