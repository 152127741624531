import OwlCarousel from "react-owl-carousel";
import LoadingSpinner from "src/components/loading-spinner";
import ENV from "src/config/ENV";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { saveSetting } from "src/store/slices/moduleSlice";

function MyDocumentContents({ loading, data }) {
  const [modules, setModules] = useCookies(["modules"]);
  const [isActive, setIsActive] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const moveTop = (module) => {
    let currentConfig = modules.modules;
    const index = currentConfig.indexOf(module);
    currentConfig.splice(index, 1);
    currentConfig[0] = module;
    setModules("modules", JSON.stringify(currentConfig));
    dispatch(saveSetting(currentConfig));
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (modules.modules && modules.modules[0] == "my-document-contents") {
      setIsActive(true);
    }
  }, [modules]);

  return (
    <>
      <LoadingSpinner active={loading}>
        {data && data.length > 0 && (
          <div
            className="main-situation"
            style={{ backgroundColor: "white", paddingTop: 20 }}
          >
            <div className="container">
              {/* {modules.modules &&
              modules.modules[0] != "my-document-contents" && (
                <a
                  style={{ cursor: "pointer" }}
                  className="d-block mb-3 upto"
                  onClick={() => moveTop("interest-document-contents")}
                >
                  <img src="/theme/img/icon-upto.svg" />
                </a>
              )} */}
              <div className="main-title text-center">
                <h2>Tình huống của tôi</h2>
              </div>
              <div>
                {data && (
                  <OwlCarousel
                    className="owl-carousel owl-theme situation-carousel"
                    loop
                    margin={10}
                    responsive={{
                      0: {
                        items: 1,
                      },
                      600: {
                        items: 3,
                      },
                    }}
                    nav
                  >
                    {data.map((item) => (
                      <Link
                        to={`/content/${item.id}`}
                        key={`slide-suggest-${item.id}`}
                        className="ln-detail"
                      >
                        <span>
                          <img
                            style={{
                              borderRadius: 10,
                            }}
                            src={
                              item.thumbnailUrl != ""
                                ? `${ENV.baseFileImageUrl}${item.thumbnailUrl}`
                                : "/theme/img/no-img-1.jpg"
                            }
                          />
                        </span>
                        <article>
                          <label>{item.name}</label>
                          <p className="lnd-tag color-9B9B9B">
                            {item.fieldCatalogName}
                            {item.fieldCatalogName && item.fieldCatalogName && (
                              <span></span>
                            )}
                            {item.subjectCatalogName}
                          </p>
                          <ul className="lnd-view">
                            <li>
                              <img src="/theme/img/icon-news.svg" />
                              {item.totalRelatedDocumentContents}
                            </li>
                            <li>
                              <img src="/theme/img/icon-page.svg" />
                              {item.totalRelatedDocuments}
                            </li>
                            <li>
                              <img src="/theme/img/icon-comment2.svg" />
                              {item.totalComments}
                            </li>
                          </ul>
                        </article>
                      </Link>
                    ))}
                  </OwlCarousel>
                )}
              </div>
            </div>
          </div>
        )}
      </LoadingSpinner>
    </>
  );
}

export default MyDocumentContents;
