import axios from "axios";

import ApiBase from "./api-base";

export default class HomeApi extends ApiBase {
  constructor() {
    super("home");
  }

  index = () => {
    const url = `${this.baseApiUrl}/index`;
    const response = axios.get(url);
    return response;
  };

  recommendations = (subjectIds, fieldIds) => {
    const url = `${this.baseApiUrl}/RecommendationDocumentContents/${subjectIds}/${fieldIds}`;
    const response = axios.get(url);
    return response;
  };

  getMyDocumentContents = () => {
    const url = `${this.baseApiUrl}/MyDocumentContents`;
    const response = axios.get(url);
    return response;
  };
}
