import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import ENV from "src/config/ENV";

function LastestComments({ data }) {
  return (
    <>
      {data && data.length > 0 && (
        <div className="box-news bn-comment-hot">
          <label>Bình luận mới</label>
          <ul>
            {data.map((item) => (
              <li>
                {item.contentType == 2 && (
                  <Link to={`/content/${item.contentId}`}>
                    <label>{item.fullName}</label>
                    <p>{item.contentName}</p>
                    {/* <p>{item.content}</p> */}
                  </Link>
                )}
                {item.contentType == 4 && (
                  <Link to={`/news/${item.contentId}`}>
                    <label>{item.fullName}</label>
                    <p>{item.contentName}</p>
                    {/* <p>{item.content}</p> */}
                  </Link>
                )}
                {item.contentType == 1 && (
                  <Link to={`/app/documents/view/${item.contentId}`}>
                    <label>{item.fullName}</label>
                    <p>{item.contentName}</p>
                    {/* <p>{item.content}</p> */}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}

export default LastestComments;
