import ApiBase from './api-base';
import axios from 'axios';

export default class UserProfileApi extends ApiBase {
  constructor() {
    super('userprofile');
  }

  me = () => {
    const url = `${this.baseApiUrl}/Me`;
    const response = axios.get(url);
    return response;
  };
}
