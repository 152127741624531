import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import ENV from "src/config/ENV";
import CommentApi from "src/api/comment-api";

function Comments({ contentType, contentId }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    if (contentId && contentType) {
      new CommentApi().getComments(contentType, contentId).then((response) => {
        setData(response.data.data);
        setTotalRows(response.data.totalItems);
      });
    }
  }, [contentType, contentId]);

  return (
    <>
      <div className="box-detail">
        <div className="main-title">
          <h2>{totalRows} bình luận</h2>
        </div>
        {data && data.length > 0 && (
          <div className="bd-comment">
            {data.map((item) => (
              <div className="bdc-detail">
                <span>
                  <img
                    src="/theme/img/avatar.png"
                    style={{ width: 40, height: 40 }}
                  />
                </span>
                <article>
                  <label>{item.fullName}</label>
                  <p>{item.content}</p>
                </article>
              </div>
            ))}

            <a href="javascript:;" className="btn-toggle p-0">
              Xem thêm bình luận <img src="/theme/img/icon-arowup.svg" />
            </a>
          </div>
        )}
      </div>
    </>
  );
}

export default Comments;
