import React from 'react';

import UserContentTagApi from 'src/api/user-content-tag-api';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

const filter = createFilterOptions();

export default function UserContentTag({ onChange }) {
  const [value, setValue] = React.useState([]);
  const [options, setOptions] = React.useState([]);

  const [open, setOpen] = React.useState(false); // if dropdown open?
  const [data, setData] = React.useState([]);
  const loading = open && data.length === 0; // is it still loading

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    new UserContentTagApi().searches().then((response) => {
      if (active) {
        setData([...response.data.data]);
      }
    });

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value]);

  return (
    <>
      <Grid container>
        <Grid item md={12}>
          <Autocomplete
            size='small'
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            loading={loading}
            value={value}
            multiple
            id="tags-outlined"
            options={data}
            getOptionLabel={(option) => option.tag}
            isOptionEqualToValue={(option, value) => option.tag === value.tag}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            filterSelectedOptions
            renderInput={(params) => (
              <TextField {...params} label="Tìm kiếm theo thẻ" placeholder="Nhập tên tag" />
            )}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              return filtered;
            }}
            onChange={(event, newValue) => {
              if (typeof newValue === "string") {
                setValue({
                  tag: newValue,
                });
              } else if (newValue && newValue.inputValue) {
                // Create a new value from the user input
                // addTag(newValue.inputValue);
                setValue({
                  tag: newValue.inputValue,
                });
              } else {
                // check if new input
                setValue(newValue);
              }
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
