import { configureStore } from "@reduxjs/toolkit";

import { accountSlice } from "./slices/accountSlice";
import { appSlice } from "./slices/appSlice";
import { moduleSlice } from "./slices/moduleSlice";

export const store = configureStore({
  reducer: {
    [accountSlice.name]: accountSlice.reducer,
    [appSlice.name]: appSlice.reducer,
    [moduleSlice.name]: moduleSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
