import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import ENV from "src/config/ENV";
import LoadingSpinner from "src/components/loading-spinner";
import CommentApi from "src/api/comment-api";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

function AddComment({ contentType, contentId }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const [content, setContent] = useState("");
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const save = () => {
    setLoading(true);
    const entity = {
      contentId: contentId,
      contentType: contentType,
      email: email,
      content: content,
      fullName: fullName,
    };
    new CommentApi().post(entity).then((respone) => {
      setLoading(false);
      setOpen(true);
    });
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Bình luận của bạn đã được gửi đi, vui lòng chờ duyệt."
        action={action}
      />
      <div className="box-detail">
        <div className="main-title">
          <h2>Để lại bình luận của bạn ở đây</h2>
        </div>
        <LoadingSpinner active={loading}>
          <form className="bd-form">
            <i className="mb-3 d-block">*Địa chỉ Email của bạn sẽ được ẩn đi</i>
            <div className="mb-3">
              <label for="exampleFormControlInput1" className="form-label">
                Bình luận*
              </label>
              <textarea
                className="form-control"
                rows="3"
                value={content}
                onChange={(e) => setContent(e.target.value)}
              ></textarea>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="mb-3">
                  <label for="exampleFormControlInput1" className="form-label">
                    Tên*
                  </label>
                  <input
                    type="text"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    className="form-control"
                    placeholder=""
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="mb-3">
                  <label for="exampleFormControlInput1" className="form-label">
                    Email*
                  </label>
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    className="form-control"
                    placeholder="name@example.com"
                  />
                </div>
              </div>
            </div>

            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
              <label className="form-check-label" for="flexCheckDefault">
                Lưu tên của bạn cho bình luận tiếp theo
              </label>
            </div>
            <button
              type="button"
              className="btn btn-lg btn-primary"
              onClick={save}
            >
              Bình luận
            </button>
          </form>
        </LoadingSpinner>
      </div>
    </>
  );
}

export default AddComment;
