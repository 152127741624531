import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  setting: [],
};

export const moduleSlice = createSlice({
  name: "module",
  initialState,
  reducers: {
    saveSetting: (state, action) => {
      state.setting = { ...action.payload };
    },
  },
});

export const { saveSetting } = moduleSlice.actions;
export const setting = (state) => state.module.setting;
export default moduleSlice.reducer;
