import React, {
  useEffect,
  useState,
} from 'react';

import { Link } from 'react-router-dom';
import DocumentContentApi from 'src/api/document-content-api';

import { Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

function DocumentContentList() {
  const [data, setData] = useState(null);

  useEffect(() => {
    new DocumentContentApi().searches({ PageNumber: 0 }).then((response) => {
      setData(response.data);
    });
  }, []);

  return (
    <>
      <b>Tình huống</b>
      <br/>
      <br/>
      <Grid container alignContent={"center"} alignItems={"center"} spacing={6}>
        {data &&
          data.documentContents.map((item) => (
            <Grid item md={4}>
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <Typography color="text.secondary" gutterBottom>
                    {item.code} {item.name}
                  </Typography>
                  <Typography component="div">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.shortContent,
                      }}
                    />
                  </Typography>
                </CardContent>
                <CardActions>
                  <Link to={`/documentcontent/${item.id}`}>Xem</Link>
                </CardActions>
              </Card>
            </Grid>
          ))}
      </Grid>
    </>
  );
}

export default DocumentContentList;
