export enum CatalogType {
  DOCUMENT_FIELD_CATALOG = 1, //Lĩnh vực văn bản
  DOCUMENT_ISSUER_CATALOG = 3, //Đơn vị phát hành
  DOCUMENT_CONTENT_CATALOG = 4, // Danh mục Nội dung bác tách văn bản
  DOCUMENT_TYPE_CATALOG = 5, // Loại Văn bản
  DOCUMENT_SIGNER_CATALOG = 6, // Người ký văn bản
  DOCUMENT_SUBJECT_CATALOG = 7, // Chủ đề văn bản
  SIGNER_POSITION_CATALOG = 8, // Chức danh người ký văn bản
  DOCUMENT_GROUP_CATALOG = 9, // Nhóm văn bản
  NEWS_CATALOG = 10, // Nhóm văn bản
  CONTENT_TYPE = 11, // ContentType
  SERVICE_CATALOG = 12, // Dich vu
  BUSINESS_LINE_CATALOG = 13, // Ngành nghề kinh doanh
  GOVERNMENT_AGENCY_CATALOG = 14, // Cấp quản lý
  REGION_CATALOG = 15, // Địa bàn
  QUESTION_CATALOG = 16, // Danh mục Câu hỏi
  UserGroupCatalog = 17, // Nhóm người dùng
  MarketCatalog = 18, // Danh mục Câu hỏi
  DocumentStatusCatalog = 19, // Danh mục Câu hỏi
  DocumentFolderCatalog = 20, // Danh mục Câu hỏi
  DocumentContentGroupCatalog = 21, // Danh mục Câu hỏi
}
