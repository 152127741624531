import React, { useEffect } from "react";

import UserContentTagApi from "src/api/user-content-tag-api";

import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

const filter = createFilterOptions();

export default function UserContentTag2({
  contentId = 0,
  contentType = 0,
  tags = [],
}) {
  const [value, setValue] = React.useState(tags);
  const [options, setOptions] = React.useState([]);

  const [open, setOpen] = React.useState(false); // if dropdown open?
  const [data, setData] = React.useState([]);
  const loading = open && data.length === 0; // is it still loading

  const handleClick = () => {
    console.info("You clicked the Chip.");
  };

  const handleDelete = () => {
    console.info("You clicked the delete icon.");
  };

  useEffect(() => {
    setValue(tags);
  }, [tags]);

  const addTag = (value) => {
    if (value && value.length >= 3) {
      new UserContentTagApi()
        .post({
          id: 0,
          tag: value,
          contentId: contentId,
          contentType: contentType,
        })
        .then((response) => {
          setValue([
            ...value,
            {
              tag: response.data.value.tag,
              id: response.data.value.id,
            },
          ]);

          let currentTags = [...data];
          currentTags.push(response.data.value);
          setData([...currentTags]);
        });
    }
  };

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    new UserContentTagApi().searches().then((response) => {
      if (active) {
        setData([...response.data.data]);
      }
    });

    return () => {
      active = false;
    };
  }, [loading]);

  return (
    <>
      <Grid container>
        <Grid item md={12} xs={12}>
          <Autocomplete
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            loading={loading}
            value={value}
            multiple
            freeSolo
            id="tags-outlined"
            options={data}
            getOptionLabel={(option) => option.tag}
            isOptionEqualToValue={(option, value) => option.tag === value.tag}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Phân loại"
                size="small"
                placeholder="Nhập tên tag"
              />
            )}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some(
                (option) => inputValue === option.tag
              );
              if (inputValue !== "" && !isExisting && inputValue.length >= 3) {
                filtered.push({
                  name: inputValue,
                  tag: `Thêm "${inputValue}"`,
                  id: 0,
                });
              }

              return filtered;
            }}
            onChange={(event, newValue, reason, detail) => {
              if (reason === "removeOption") {
                new UserContentTagApi()
                  .delete(detail.option)
                  .then((response) => {
                    //setData([...response.data.data]);
                  });
              }
              if (reason === "selectOption") {
                let newTag = { ...detail.option };
                if (detail.option.name) {
                  newTag.tag = detail.option.name;
                }
                newTag.contentId = contentId;
                newTag.contentType = contentType;
                new UserContentTagApi().post(newTag).then((response) => {
                  //setData([...response.data.data]);
                });
              }
              if (typeof newValue === "string") {
                setValue({
                  tag: newValue,
                });
              } else if (newValue && newValue.inputValue) {
                // Create a new value from the user input
                // addTag(newValue.inputValue);
                setValue({
                  tag: newValue.inputValue,
                });
              } else {
                // check if new input
                var newTag = newValue.find((x) => x.id == 0);
                if (newTag) {
                  addTag(newTag.name);
                }
                setValue(newValue);
              }
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
