import React, { lazy, useEffect, useState } from "react";

import UserApi from "src/api/user-api";
import UserProfileApi from "src/api/user-profile-api";
import { Button, Grid, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import LoadingSpinner from "src/components/loading-spinner";
import ConfigInterestField from "./interest-field";
import ConfigInterestSubject from "./interest-subject";
import ConfigInterestDepartment from "./interest-from-department";
import ConfigInterestRegion from "./interest-from-region";

function UserSetting() {
  const [data, setData] = useState(null);
  const [
    checkedDocumentContentSubjectIds,
    setCheckedDocumentContentSubjectIds,
  ] = useState([]);
  const [checkedDocumentContentFieldIds, setCheckedDocumentContentFieldIds] =
    useState([]);
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(false);

  const [
    checkedDocumentContentDepartmentIds,
    setCheckedDocumentContentDepartmentIds,
  ] = useState([]);

  const [checkedDocumentContentRegionIds, setCheckedDocumentContentRegionIds] =
    useState([]);

  useEffect(() => {
    new UserApi().me().then((response) => {
      setData(response.data);
    });

    const api = new UserProfileApi();
    api.me().then((response) => {
      setUserProfile(response.data.value);

      setCheckedDocumentContentSubjectIds(
        response.data.value.receiveNotificationDocumentContentFromSubjectIdList
      );

      setCheckedDocumentContentFieldIds(
        response.data.value.receiveNotificationDocumentContentFromFieldIdList
      );

      setCheckedDocumentContentDepartmentIds(
        response.data.value
          .receiveNotificationDocumentContentFromDepartmentIdList
      );

      setCheckedDocumentContentRegionIds(
        response.data.value.receiveNotificationDocumentFromRegionIdList
      );
    });
  }, []);

  const onDocumentContentSubjectIdsChanged = (value) => {
    setCheckedDocumentContentSubjectIds(value);
  };

  const onDocumentContentFieldIdsChanged = (value) => {
    setCheckedDocumentContentFieldIds(value);
  };

  const onDocumentContentRegionIdsChanged = (value) => {
    setCheckedDocumentContentRegionIds(value);
  };

  const onDocumentContentDepartmentIdsChanged = (value) => {
    setCheckedDocumentContentDepartmentIds(value);
  };

  const handleSave = () => {
    setLoading(true);
    const api = new UserProfileApi();
    let entity = JSON.parse(JSON.stringify(userProfile));
    entity.Setting = JSON.stringify(userProfile.userSetting);
    entity.UserSetting = null;

    entity.ReceiveNotificationDocumentContentFromSubjectIds =
      checkedDocumentContentSubjectIds.join();
    entity.ReceiveNotificationDocumentContentFromFieldIds =
      checkedDocumentContentFieldIds.join();

    entity.ReceiveNotificationDocumentFromSubjectIds =
      checkedDocumentContentSubjectIds.join();

    entity.ReceiveNotificationDocumentFromFieldIds =
      checkedDocumentContentFieldIds.join();

    entity.ReceiveNotificationDocumentContentFromDepartmentIds =
      checkedDocumentContentDepartmentIds.join();

    entity.ReceiveNotificationDocumentFromRegionIds =
      checkedDocumentContentRegionIds.join();

    api.save(entity).then((response) => {
      setLoading(false);
      if (response.data.isSuccess) {
        api.me().then((response) => {
          // this.setState({ userProfile: response.data.Value });
        });
      }
    });
  };

  return (
    <>
      <LoadingSpinner active={loading}>
        <div className="main-news" style={{ minHeight: 500, paddingTop: 50 }}>
          <div
            className="container"
            style={{ boxShadow: "0px 4px 16px #0000001f" }}
          >
            {data && (
              <table className="table table-user-information">
                <tbody>
                  <tr>
                    <td colSpan={2}>
                      <br />
                      <h5>Cấu hình tài khoản</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <Typography color="text.secondary" gutterBottom>
                        Họ tên
                      </Typography>
                    </td>
                    <td>
                      <TextField variant="standard" value={data.fullName} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography color="text.secondary" gutterBottom>
                        Email
                      </Typography>
                    </td>
                    <td>
                      <TextField variant="standard" value={data.email} />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <Typography color="text.primary" gutterBottom>
                        <b> Cấu hình Lĩnh vực/ Chủ đề quan tâm </b>
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      {userProfile && (
                        <ConfigInterestSubject
                          onChange={onDocumentContentSubjectIdsChanged}
                          init={
                            userProfile.receiveNotificationDocumentContentFromSubjectIdList
                          }
                          data={
                            userProfile.notificationDocumentContentFromSubjects
                          }
                        />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      {userProfile && (
                        <ConfigInterestField
                          onChange={onDocumentContentFieldIdsChanged}
                          data={
                            userProfile.notificationDocumentContentFromFields
                          }
                          init={
                            userProfile.receiveNotificationDocumentContentFromFieldIdList
                          }
                        />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      {userProfile && (
                        <ConfigInterestDepartment
                          onChange={onDocumentContentDepartmentIdsChanged}
                          data={
                            userProfile.notificationDocumentContentFromDepartments
                          }
                          init={
                            userProfile.receiveNotificationDocumentContentFromDepartmentIdList
                          }
                        />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      {userProfile && (
                        <ConfigInterestRegion
                          onChange={onDocumentContentRegionIdsChanged}
                          data={userProfile.notificationDocumentFromRegions}
                          init={
                            userProfile.receiveNotificationDocumentFromRegionIdList
                          }
                        />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <Button
                        variant="contained"
                        onClick={handleSave}
                        disabled={loading}
                      >
                        Cập nhật
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </div>
      </LoadingSpinner>
    </>
  );
}

export default UserSetting;
