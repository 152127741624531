import * as React from 'react';

interface FooterProps {
  description: string;
  title: string;
}

export default function Footer(props: FooterProps) {
  return (
    <div className="footer">
      <div className="container">
        <a href="" className="logo-page">
          <img src="/theme/img/logo.png" />
        </a>

        <div className="footer-content">
          <div className="row align-items-end">
            <div className="col-md-4">
              <ul className="footer-address">
                <li>
                  <label>Tên công ty</label>
                </li>
                <li>
                  Số điện thoại: <a href=""></a>
                </li>
                <li>
                  Email: <a href=""></a>
                </li>
                <li>Địa chỉ: </li>
              </ul>
            </div>
            <div className="col-md-4">
              <ul className="footer-menu">
                <li>
                  <a href="">Giới thiệu</a>
                </li>
                <li>
                  <a href="">Liên hệ</a>
                </li>
                <li>
                  <a href="">Đăng ký</a>
                </li>
                <li>
                  <a href="">Đăng nhập</a>
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <div className="footer-app">
                <p>Tải app tại đây:</p>
                <ul>
                  <li>
                    <a href="">
                      <img src="/theme/img/appstore.png" />
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <img src="/theme/img/googleplay.png" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <p>Copyright © 2024</p>
          <ul>
            <li>
              <a href="">
                <img src="/theme/img/logo-facebook.svg" />
              </a>
            </li>
            <li>
              <a href="">
                <img src="/theme/img/logo-zalo.svg" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
