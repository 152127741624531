import axios from 'axios';
import ENV from 'src/config/ENV';
import User from 'src/interfaces/User';
import Cookies from 'universal-cookie';

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };

  login = (userName: string, password: string, isAD: boolean = false) => {
    let data =
      'username=' +
      userName +
      '&password=' +
      password +
      '&isAD=' +
      isAD.toString();
    const url = `${ENV.baseApiEndpoint}/authentication`;
    return axios.post(url, data, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    });
  };

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    this.setSession(accessToken);
  }

  loginWithEmailAndPassword = (email, password) => {
    this.setSession(null);
    var data =
      'grant_type=password&username=' +
      email +
      '&password=' +
      password +
      '&client_id=' +
      ENV.clientId;
    var url = ENV.baseApiEndpoint + '/oauth2/token';
    return axios.post(url, data, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    });
  };

  logout = () => {
    this.setSession(null);
  };

  setSession = (accessToken: string) => {
    const cookies = new Cookies();
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      cookies.set('token', accessToken, { path: '/' });
    } else {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('userProfile');
      cookies.set('token', null, { path: '/' });
      delete axios.defaults.headers.common.Authorization;
    }
  };

  getAccessToken = () => localStorage.getItem('accessToken');

  setUserProfile = userProfile => {
    localStorage.setItem('userProfile', JSON.stringify(userProfile));
  };

  getUserProfile = (): User => JSON.parse(localStorage.getItem('userProfile'));

  isAuthenticated = () => !!this.getAccessToken();
}

const authService = new AuthService();

export default authService;
