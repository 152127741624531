import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SearchApi from "src/api/search-api";
import { Link, useParams } from "react-router-dom";
import {
  DocumentFieldCatalogApi,
  DocumentSubjectCatalogApi,
} from "src/api/catalog-api";
import ContentApi from "src/api/content-api";
import ENV from "src/config/ENV";
import LoadingSpinner from "src/components/loading-spinner";
import { setting } from "src/store/slices/appSlice";

import { Divider } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Pagination from "@mui/material/Pagination";
import Typography from "@mui/material/Typography";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import KeywordApi from "src/api/keyword-api";
import { Button, Grid, TextField } from "@mui/material";
import Box from "@mui/material/Box";

function Search() {
  const [data, setData] = useState(null);
  const [type, setType] = useState(0);
  const [keyword1, setKeyword1] = useState(null);
  const [keywords, setKeywords] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const currentSetting = useSelector(setting);

  let { keyword } = useParams();

  useEffect(() => {
    if (!keyword1) return;
    new SearchApi()
      .searches({ keyword: keyword1, viewType: type, page: 1 })
      .then((response) => {
        setData(response.data);
      });
  }, [type, keyword1]);

  useEffect(() => {
    if (!keyword) return;
    setKeyword1(keyword);
  }, [keyword]);

  useEffect(() => {
    new KeywordApi().searches().then((response) => {
      setKeywords(response.data.data);
    });
  }, []);

  const pageChanged = (e, page) => {
    setPage(page);
  };

  const getLink = (contentType, contentId) => {
    if (contentType == 1) {
      return `/app/documents/view/${contentId}`;
    }
    if (contentType == 2) {
      return `/contents/${contentId}`;
    }
    if (contentType == 4) {
      return `/news/${contentId}`;
    }
    return "/";
  };

  return (
    <>
      <div className="container">
        <nav aria-label="breadcrumb" className="">
          <ol className="breadcrumb bc-edit my-4">
            <li className="breadcrumb-item">
              <Link to="/" className="icon-home">
                <img src="/theme/img/icon-home.svg" />
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Tìm kiếm
            </li>
          </ol>
        </nav>
      </div>
      {currentSetting && currentSetting.isOpenFilter && (
        <div className="main-search pt-0" id="over_map">
          <div className="container">
            <div className="ms-search">
              <div className="ms-search-fillter dropdown">
                <div className="form-search-edit">
                  <input
                    type="text"
                    name=""
                    value={keyword1}
                    placeholder="Nhập từ khóa"
                    onChange={(e) => setKeyword1(e.target.value)}
                  />
                  <img
                    className="icon-search"
                    src="/theme/img/icon-search.svg"
                  />
                </div>
              </div>
              {keywords && (
                <div className="search-tag">
                  <label>Phổ biến:</label>
                  <ul>
                    {keywords.map((item) => (
                      <li>
                        <Link to={`/search/${encodeURIComponent(item.name)}`}>
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="main-news">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="list-news-all">
                <LoadingSpinner active={loading}>
                  <>
                    {data &&
                      data.result.map((item) => (
                        <Link
                          to={getLink(item.contentType, item.contentId)}
                          className="list-news-detail ln-detail search-result-row"
                        >
                          <article>
                            <label
                              style={{ cursor: "pointer" }}
                              dangerouslySetInnerHTML={{
                                __html: item.name,
                              }}
                            ></label>
                            <p className="lnd-tag color-9B9B9B">
                              {item.contentTypeName}
                            </p>
                            <p className="lnd-text">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.description,
                                }}
                              />
                            </p>
                            {/* <ul className="lnd-view">
                              <li>
                                <img src="theme/img/icon-news.svg" /> 15
                              </li>
                              <li>
                                <img src="theme/img/icon-page.svg" /> 1000
                              </li>
                              <li>
                                <img src="theme/img/icon-comment2.svg" /> 10
                              </li>
                            </ul> */}
                          </article>
                        </Link>
                      ))}
                    <Pagination
                      color="secondary"
                      onChange={pageChanged}
                      count={totalPages}
                      variant="outlined"
                    />
                  </>
                </LoadingSpinner>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Search;
