import ApiBase from './api-base';
import axios from 'axios';

export default class UserNotificationApi extends ApiBase {
  constructor() {
    super('usernotification');
  }

  scan = () => {
    const url = `${this.baseApiUrl}/Scan`;
    const response = axios.get(url);
    return response;
  };

  read = (id: number) => {
    const url = `${this.baseApiUrl}/Read/${id}`;
    const response = axios.get(url);
    return response;
  };
}
