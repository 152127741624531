import axios from 'axios';

import ApiBase from './api-base';

export default class SearchApi extends ApiBase {
  constructor() {
    super("search");
  }

  hello = () => {
    const url = `${this.baseApiUrl}/hello`;
    const response = axios.post(url);
    return response;
  };

  searches = (request: any) => {
    const url = `${this.baseApiUrl}`;
    const response = axios.post(url, request);
    return response;
  };
}
