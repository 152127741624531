import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  setting: { isOpenFilter: true },
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    saveSetting: (state, action) => {
      state.setting = { ...action.payload };
    },
  },
});

export const { saveSetting } = appSlice.actions;
export const setting = (state) => state.app.setting;
export default appSlice.reducer;
