import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import UserApi from "src/api/user-api";
import moment from "moment";
import UserNotificationApi from "src/api/user-notification-api";

import {
  Button,
  Grid,
  Card,
  CardContent,
  Divider,
  TableHead,
  Chip,
  ButtonGroup,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import NotificationDetail from "../detail";

function NotificationList() {
  const [data, setData] = useState([]);
  const [openDetail, setOpenDetail] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [viewId, setViewId] = useState(0);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    new UserNotificationApi()
      .searches({
        Status: -1,
        PageSize: rowsPerPage,
        PageNumber: page,
      })
      .then((response) => {
        setData(response.data.data);
        setTotalRows(response.data.totalItems);
        setTotalPages(response.data.pagesCount);
      });
  };

  const handleView = (id) => {
    setOpenDetail(true);
    setViewId(id);
  };

  const handleCloseView = () => {
    setOpenDetail(false);
    fetchData();
  };

  return (
    <>
      <div className="container">
        <nav aria-label="breadcrumb" className="">
          <ol className="breadcrumb bc-edit my-4">
            <li className="breadcrumb-item">
              <Link to="/" className="icon-home">
                <img src="/theme/img/icon-home.svg" />
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Thông báo
            </li>
          </ol>
        </nav>
      </div>
      <div className="main-news">
        <div
          className="container"
          style={{
            boxShadow: "0px 4px 16px #0000001f",
            paddingRight: 0,
            paddingLeft: 0,
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "50px" }}>STT</TableCell>
                <TableCell style={{}}>Tiêu đề</TableCell>
                <TableCell>Mô tả</TableCell>
                <TableCell style={{}}>Trạng thái</TableCell>
                <TableCell style={{}}>Thời gian</TableCell>
                <TableCell style={{}}>Thao tác</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map((item, index) => (
                  <TableRow hover key={item.id}>
                    <TableCell style={{ textAlign: "center" }}>
                      {index + 1}
                    </TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.shortContent}</TableCell>
                    <TableCell>
                      {item.isRead === true && (
                        <Chip
                          label="Đã đọc"
                          color="success"
                          variant="outlined"
                        />
                      )}
                      {item.isRead === false && (
                        <Chip label="Chưa đọc" variant="outlined" />
                      )}
                    </TableCell>
                    <TableCell>
                      {moment(item.createdTime).format("YYYY-MM-DD HH:mm:ss")}
                    </TableCell>
                    <TableCell>
                      <ButtonGroup
                        color="primary"
                        aria-label="outlined primary button group"
                      >
                        <Button
                          color="primary"
                          size="small"
                          variant="outlined"
                          onClick={() => {
                            handleView(item.id);
                          }}
                        >
                          Xem
                        </Button>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      </div>
      {openDetail && viewId > 0 && (
        <NotificationDetail onClose={handleCloseView} id={viewId} />
      )}
    </>
  );
}

export default NotificationList;
