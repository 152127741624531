import React from 'react';

// import { DocumentSubjectCatalogApi } from 'src/api/catalog-api';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import FolderIcon from '@mui/icons-material/Folder';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Avatar from '@mui/material/Avatar';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

export default function ConfigInterestSubject({ data, init, onChange }) {
  const [dense, setDense] = React.useState(false);
  const [checked, setChecked] = React.useState(init ?? []);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    if (onChange) {
      onChange(newChecked);
    }
  };

  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography style={{fontWeight: 'bold'}}>Chủ đề quan tâm ({checked.length})</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List dense={dense}>
            {data &&
              data.map((item) => {
                const labelId = `checkbox-list-secondary-label-${item.id}`;
                return (
                  <ListItem
                    key={item.id}
                    secondaryAction={
                      <Checkbox
                        edge="end"
                        onChange={handleToggle(item.id)}
                        checked={checked.indexOf(item.id) !== -1}
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    }
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <FolderIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={item.name} />
                  </ListItem>
                );
              })}
          </List>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
