import React, {
  useEffect,
  useState,
} from 'react';

import HomeApi from 'src/api/home-api';

import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

function NewsList() {
  const [data, setData] = useState(null);

  useEffect(() => {
    new HomeApi().index().then((response) => {
      setData(response.data);
    });
  }, []);

  return (
    <>
      <h3>Văn bản</h3>
      <Grid container alignContent={"center"} alignItems={"center"} spacing={6}>
        {data &&
          data.Documents.map((item) => (
            <Grid item md={2}>
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <Typography color="text.secondary" gutterBottom>
                    {item.Code} {item.Name}
                  </Typography>
                  <Typography component="div">
                    <div
                      dangerouslySetInnerHTML={{ __html: item.ShortContent }}
                    />
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small">Xem</Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
      </Grid> 
    </>
  );
}

export default NewsList;
