import React, { useRef, useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import UserNotificationApi from "src/api/user-notification-api";
import UsertNotification from "src/interfaces/UserNotification";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
  Badge,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { ThemeProvider, createMuiTheme, makeStyles } from "@mui/styles";

import {
  Bell as BellIcon,
  Package as PackageIcon,
  MessageCircle as MessageIcon,
  Truck as TruckIcon,
} from "react-feather";

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 320,
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
}));

function Notifications({ notifications }) {
  const classes = useStyles();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [count, setCount] = useState(0);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (notifications) {
      setCount(notifications.length)
    }
  }, [notifications]);

  return (
    <>
      <Tooltip title="Notifications">
        <a ref={ref} onClick={handleOpen} className="btn-icon-noti">
          <span>{count}</span>
          <img src="/theme/img/icon-noti.svg" />
        </a>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box p={2}>
          <Typography variant="body" color="textPrimary">
            Thông báo
          </Typography>
        </Box>
        {!notifications || notifications.length === 0 ? (
          <Box p={2}>
            <Typography variant="p" color="textPrimary">
              Hiện tại chưa có thông báo mới nào.
            </Typography>
          </Box>
        ) : (
          <>
            <List className={classes.list} disablePadding>
              {notifications.map((notification) => {
                return (
                  <ListItem
                    className={classes.listItem}
                    component={RouterLink}
                    divider
                    key={notification.id}
                    to="#"
                  >
                    <ListItemAvatar>
                      <Avatar className={classes.icon}>
                        <SvgIcon fontSize="small">
                          <BellIcon />
                        </SvgIcon>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={notification.name}
                      primaryTypographyProps={{
                        variant: "subtitle2",
                        color: "textPrimary",
                      }}
                      secondary={notification.shortContent}
                    />
                  </ListItem>
                );
              })}
            </List>
          </>
        )}
        <Box p={1} display="flex" justifyContent="center">
          <Button
            dense
            component={RouterLink}
            onClick={handleClose}
            size="small"
            to="/notifications"
          >
            Xem tất cả
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default Notifications;
