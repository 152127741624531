import React, { useEffect, useState } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ENV from "src/config/ENV";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import ContentApi from "src/api/content-api";
// import UserContentTag from "src/components/tag";
import { selectedAccount } from "src/store/slices/accountSlice";
import UserProfileApi from "src/api/user-profile-api";
import LoadingSpinner from "src/components/loading-spinner";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import moment from "moment";
import NewsApi from "src/api/news-api";
import CommentApi from "src/api/comment-api";
import {
  DocumentFieldCatalogApi,
  DocumentSubjectCatalogApi,
} from "src/api/catalog-api";
import DocumentApi from "src/api/document-api";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InteresetCatalogs from "src/components/interest-catalogs";
import AddComment from "src/components/comments/add";
import Comments from "src/components/comments/list";
import LastestComments from "src/components/comments/lastest";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function DocumentDetail() {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [featureNews, setFeatureNews] = useState(null);
  const [comments, setComments] = useState(null);
  const [subjectCatalogs, setSubjectCatalogs] = useState([]);
  const [fieldCatalogs, setFieldCatalogs] = useState([]);
  const currentUser = useSelector(selectedAccount);

  const [data, setData] = useState(null);
  let { id } = useParams();

  useEffect(() => {
    if (!id) return;
    setLoading(true);
    new DocumentApi().get(id).then((response) => {
      setData(response.data);
      setLoading(false);
    });

    new NewsApi().getFeatureNews().then((response) => {
      setFeatureNews(response.data.data);
    });

    new CommentApi().getLatest().then((response) => {
      setComments(response.data.data);
    });
  }, [id]);

  useEffect(() => {
    if (currentUser && currentUser.id) {
      const api = new UserProfileApi();
      api.me().then((response) => {
        setProfile(response.data.value);
      });
    }
  }, [currentUser]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    if (profile && profile.notificationDocumentContentFromFields) {
      return;
    }

    DocumentSubjectCatalogApi.searches(null).then((response) => {
      setSubjectCatalogs(response.data.data);
    });

    DocumentFieldCatalogApi.searches(null).then((response) => {
      setFieldCatalogs(response.data.data);
    });
  }, [profile]);

  return (
    <>
      <div className="container">
        <nav aria-label="breadcrumb" className="">
          <ol className="breadcrumb bc-edit my-4">
            <li className="breadcrumb-item">
              <Link to="/" className="icon-home">
                <img src="/theme/img/icon-home.svg" />
              </Link>
            </li>
            {data && data.fieldCatalogName && data.fieldCatalogName != "" && (
              <li className="breadcrumb-item">
                <Link to={`/contents/${data.fieldCatalogId}/0`}>
                  {data.fieldCatalogName}
                </Link>
              </li>
            )}
            {data &&
              data.subjectCatalogName &&
              data.subjectCatalogName != "" && (
                <li className="breadcrumb-item">
                  <Link to={`/contents/0/${data.subjectCatalogId}`}>
                    {data.subjectCatalogName}
                  </Link>
                </li>
              )}
          </ol>
        </nav>
      </div>
      <div className="main-news">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <LoadingSpinner active={loading}>
                {data && (
                  <>
                    <div className="box-posts">
                      <div className="bp-title bp-title-other">
                        <Link to="/contents">
                          <img src="/theme/img/icon-back.svg" />
                        </Link>
                        <article>
                          <h2>{data.code}</h2>
                        </article>
                      </div>
                      <br />
                      <div className="bp-content">
                        <TableContainer component={Paper}>
                          <Table aria-label="customized table">
                            <TableBody>
                              <StyledTableRow>
                                <StyledTableCell
                                  component="th"
                                  scope="row"
                                  style={{ width: 190 }}
                                >
                                  Ký hiệu
                                </StyledTableCell>
                                <StyledTableCell>{data.code}</StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow>
                                <StyledTableCell
                                  component="th"
                                  scope="row"
                                  style={{ width: 190 }}
                                >
                                  Trích yếu
                                </StyledTableCell>
                                <StyledTableCell>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: data.shortContent,
                                    }}
                                  />
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow>
                                <StyledTableCell
                                  component="th"
                                  scope="row"
                                  style={{ width: 190 }}
                                >
                                  Loại
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data.documentTypeCatalogName}
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow>
                                <StyledTableCell
                                  component="th"
                                  scope="row"
                                  style={{ width: 190 }}
                                >
                                  Cơ quan ban hành
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data.issuerName}
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow>
                                <StyledTableCell
                                  component="th"
                                  scope="row"
                                  style={{ width: 190 }}
                                >
                                  Ngày ban hành
                                </StyledTableCell>
                                <StyledTableCell>
                                  {moment(data.publishDate).format(
                                    "DD/MM/YYYY"
                                  )}
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow>
                                <StyledTableCell
                                  component="th"
                                  scope="row"
                                  style={{ width: 190 }}
                                >
                                  Người ký
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data.signerName}
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow>
                                <StyledTableCell
                                  component="th"
                                  scope="row"
                                  style={{ width: 190 }}
                                >
                                  Chức danh người ký
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data.signerPositionName}
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow>
                                <StyledTableCell
                                  component="th"
                                  scope="row"
                                  style={{ width: 190 }}
                                >
                                  Trạng thái
                                </StyledTableCell>
                                <StyledTableCell>
                                  {data.validStatus != 0 && (
                                    <span className="bpt-status bpt-status-warning">
                                      {data.validStatusName}
                                    </span>
                                  )}
                                  {data.validStatus == 0 && (
                                    <span className="bpt-status bpt-status-success">
                                      {data.validStatusName}
                                    </span>
                                  )}
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow>
                                <StyledTableCell
                                  component="th"
                                  scope="row"
                                  style={{ width: 190 }}
                                >
                                  File đính kèm
                                </StyledTableCell>
                                <StyledTableCell>
                                  <div>
                                    {data.files.map((item) => (
                                      <>
                                        <a
                                          href={`${ENV.baseFileImageUrl}/${item.path}`}
                                          target="_blank"
                                        >
                                          <label style={{ cursor: "pointer" }}>
                                            {item.name}
                                          </label>
                                        </a>
                                        &nbsp;
                                      </>
                                    ))}
                                    {data.linkFileArray.map((item) => (
                                      <>
                                        <a
                                          href={`${item.link}`}
                                          target="_blank"
                                        >
                                          <label style={{ cursor: "pointer" }}>
                                            {item.fileName}
                                          </label>
                                        </a>
                                        &nbsp;
                                      </>
                                    ))}
                                  </div>
                                </StyledTableCell>
                              </StyledTableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </div>
                    {/* {data.files && data.files.length > 0 && (
                      <div className="Post-document">
                        <div className="main-title mb-3">
                          <h3>Tài liệu liên quan</h3>
                        </div>
                        <OwlCarousel
                          className="owl-carousel owl-theme document-carousel"
                          items={2}
                          margin={10}
                          nav
                          responsive={{
                            0: {
                              items: 1,
                            },
                            600: {
                              items: 2,
                            },
                          }}
                        >
                          {data.files.map((item) => (
                            <a
                              href={`${ENV.baseFileImageUrl}/${item.path}`}
                              target="_blank"
                              className="dc-detail"
                            >
                              <span>
                                <img src="/theme/img/icon-page-white.svg" />
                              </span>
                              <article>
                                <label style={{ cursor: "pointer" }}>
                                  {item.name}
                                </label>
                                <p className="dcd-tag">(Nguồn gốc tài liệu)</p>
                                <p className="dcd-id">
                                  Số hiệu: {item.id}
                                  <span className="bpt-status bpt-status-warning">
                                    Hết hiệu lực 1 phần{" "}
                                  </span>
                                </p>
                              </article>
                            </a>
                          ))}
                        </OwlCarousel>
                      </div>
                    )} */}

                    {/* {profile && id && data && (
                      <div style={{ padding: "10 0" }}>
                        <UserContentTag
                          contentType={2}
                          contentId={parseInt(id, 10)}
                          tags={data.tags}
                        />
                        <br />
                      </div>
                    )} */}

                    {(data.relatedDocuments.length > 0 ||
                      data.relatedLookupDocuments.length > 0) && (
                        <div className="box-detail">
                          <div className="main-title">
                            <h3>Văn bản, Tài liệu liên quan</h3>
                          </div>
                          <div className="bd-relate">
                            {data.relatedLookupDocuments.map((item) => (
                              <Link
                                to={`/app/documents/view/${item.id}`}
                                className="ln-detail"
                              >
                                <span>
                                  <img
                                    style={{
                                      borderRadius: 10,
                                    }}
                                    src={
                                      item.thumbnailUrl != ""
                                        ? `${ENV.baseFileImageUrl}${item.thumbnailUrl}`
                                        : "/theme/img/no-img-1.jpg"
                                    }
                                  />
                                </span>
                                <article>
                                  <label style={{ cursor: "pointer" }}>
                                    {item.code} {item.shortContent}
                                  </label>
                                  <p className="lnd-tag mb-2 color-9B9B9B">
                                    {item.fieldCatalogName}
                                    {item.fieldCatalogName &&
                                      item.fieldCatalogName != "" &&
                                      item.subjectCatalogName != "" &&
                                      item.subjectCatalogName && <span></span>}
                                    {item.subjectCatalogName}
                                  </p>
                                  <ul className="lnd-view">
                                    <li>
                                      <img src="/theme/img/icon-calenda.svg" />
                                      {moment(item.createdTime).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </li>
                                    <li>
                                      {item.validStatus != 0 && (
                                        <span className="bpt-status bpt-status-warning">
                                          {item.validStatusName}
                                        </span>
                                      )}
                                      {item.validStatus == 0 && (
                                        <span className="bpt-status bpt-status-success">
                                          {item.validStatusName}
                                        </span>
                                      )}
                                    </li>
                                  </ul>
                                </article>
                              </Link>
                            ))}
                            {data.relatedDocuments.map((item) => (
                              <Link
                                to={`/app/documents/view/${item.id}`}
                                className="ln-detail"
                              >
                                <span>
                                  <img
                                    style={{
                                      borderRadius: 10,
                                    }}
                                    src={
                                      item.thumbnailUrl != ""
                                        ? `${ENV.baseFileImageUrl}${item.thumbnailUrl}`
                                        : "/theme/img/no-img-1.jpg"
                                    }
                                  />
                                </span>
                                <article>
                                  <label style={{ cursor: "pointer" }}>
                                    {item.code} {item.shortContent}
                                  </label>
                                  <p className="lnd-tag mb-2 color-9B9B9B">
                                    {item.fieldCatalogName}
                                    {item.fieldCatalogName &&
                                      item.fieldCatalogName != "" &&
                                      item.subjectCatalogName != "" &&
                                      item.subjectCatalogName && <span></span>}
                                    {item.subjectCatalogName}
                                  </p>
                                  <ul className="lnd-view">
                                    <li>
                                      <img src="/theme/img/icon-calenda.svg" />
                                      {moment(item.createdTime).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </li>
                                    <li>
                                      {item.validStatus != 0 && (
                                        <span className="bpt-status bpt-status-warning">
                                          {item.validStatusName}
                                        </span>
                                      )}
                                      {item.validStatus == 0 && (
                                        <span className="bpt-status bpt-status-success">
                                          {item.validStatusName}
                                        </span>
                                      )}
                                    </li>
                                  </ul>
                                </article>
                              </Link>
                            ))}
                          </div>
                        </div>
                      )}
                  </>
                )}
              </LoadingSpinner>

              <Comments />

              <AddComment />

              {/* Chuyên gia tư vấn */}
              <div className="main-advise">
                <div className="container">
                  <div className="main-title">
                    <h2>Chuyên gia tư vấn</h2>
                  </div>
                  <OwlCarousel
                    className="owl-carousel owl-theme advise-carousel"
                    loop
                    items={4}
                    margin={10}
                    nav
                    responsive={{
                      0: {
                        items: 1,
                      },
                      600: {
                        items: 3,
                      },
                    }}
                  >
                    <a href="">
                      <span>
                        <img src="/theme/img/img1.png" />
                      </span>
                      <label>Chuyên gia A</label>
                      <p>Pháp chế doanh nghiệp, chuyên viên pháp lý</p>
                      <span className="icon-docs">
                        <img src="/theme/img/icon-advise.svg" /> 150 Bài tư vấn
                      </span>
                    </a>
                    <a href="">
                      <span>
                        <img src="/theme/img/img2.png" />
                      </span>
                      <label>Chuyên gia A</label>
                      <p>Pháp chế doanh nghiệp, chuyên viên pháp lý</p>
                      <span className="icon-docs">
                        <img src="/theme/img/icon-advise.svg" /> 150 Bài tư vấn
                      </span>
                    </a>
                    <a href="">
                      <span>
                        <img src="/theme/img/img3.png" />
                      </span>
                      <label>Chuyên gia A</label>
                      <p>Pháp chế doanh nghiệp, chuyên viên pháp lý</p>
                      <span className="icon-docs">
                        <img src="/theme/img/icon-advise.svg" /> 150 Bài tư vấn
                      </span>
                    </a>
                    <a href="">
                      <span>
                        <img src="/theme/img/img4.png" />
                      </span>
                      <label>Chuyên gia A</label>
                      <p>Pháp chế doanh nghiệp, chuyên viên pháp lý</p>
                      <span className="icon-docs">
                        <img src="/theme/img/icon-advise.svg" /> 150 Bài tư vấn
                      </span>
                    </a>
                    <a href="">
                      <span>
                        <img src="/theme/img/img1.png" />
                      </span>
                      <label>Chuyên gia A</label>
                      <p>Pháp chế doanh nghiệp, chuyên viên pháp lý</p>
                      <span className="icon-docs">
                        <img src="/theme/img/icon-advise.svg" /> 150 Bài tư vấn
                      </span>
                    </a>
                    <a href="">
                      <span>
                        <img src="/theme/img/img2.png" />
                      </span>
                      <label>Chuyên gia A</label>
                      <p>Pháp chế doanh nghiệp, chuyên viên pháp lý</p>
                      <span className="icon-docs">
                        <img src="/theme/img/icon-advise.svg" /> 150 Bài tư vấn
                      </span>
                    </a>
                    <a href="">
                      <span>
                        <img src="/theme/img/img3.png" />
                      </span>
                      <label>Chuyên gia A</label>
                      <p>Pháp chế doanh nghiệp, chuyên viên pháp lý</p>
                      <span className="icon-docs">
                        <img src="/theme/img/icon-advise.svg" /> 150 Bài tư vấn
                      </span>
                    </a>
                    <a href="">
                      <span>
                        <img src="/theme/img/img4.png" />
                      </span>
                      <label>Chuyên gia A</label>
                      <p>Pháp chế doanh nghiệp, chuyên viên pháp lý</p>
                      <span className="icon-docs">
                        <img src="/theme/img/icon-advise.svg" /> 150 Bài tư vấn
                      </span>
                    </a>
                    <a href="">
                      <span>
                        <img src="/theme/img/img1.png" />
                      </span>
                      <label>Chuyên gia A</label>
                      <p>Pháp chế doanh nghiệp, chuyên viên pháp lý</p>
                      <span className="icon-docs">
                        <img src="/theme/img/icon-advise.svg" /> 150 Bài tư vấn
                      </span>
                    </a>
                    <a href="">
                      <span>
                        <img src="/theme/img/img2.png" />
                      </span>
                      <label>Chuyên gia A</label>
                      <p>Pháp chế doanh nghiệp, chuyên viên pháp lý</p>
                      <span className="icon-docs">
                        <img src="/theme/img/icon-advise.svg" /> 150 Bài tư vấn
                      </span>
                    </a>
                    <a href="">
                      <span>
                        <img src="/theme/img/img3.png" />
                      </span>
                      <label>Chuyên gia A</label>
                      <p>Pháp chế doanh nghiệp, chuyên viên pháp lý</p>
                      <span className="icon-docs">
                        <img src="/theme/img/icon-advise.svg" /> 150 Bài tư vấn
                      </span>
                    </a>
                    <a href="">
                      <span>
                        <img src="/theme/img/img4.png" />
                      </span>
                      <label>Chuyên gia A</label>
                      <p>Pháp chế doanh nghiệp, chuyên viên pháp lý</p>
                      <span className="icon-docs">
                        <img src="/theme/img/icon-advise.svg" /> 150 Bài tư vấn
                      </span>
                    </a>
                  </OwlCarousel>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <InteresetCatalogs
                profile={profile}
                subjectCatalogs={subjectCatalogs}
                fieldCatalogs={fieldCatalogs}
              />

              {featureNews && (
                <div className="box-news bn-news-hot">
                  <label>Tin nổi bật</label>
                  <ul>
                    {featureNews.map((item) => (
                      <li>
                        <Link to={`/news/${item.id}`} className="ln-detail">
                          <span>
                            <img
                              src={
                                item.thumbnailUrl != ""
                                  ? `${ENV.baseFileImageUrl}${item.thumbnailUrl}`
                                  : "/theme/img/no-img-1.jpg"
                              }
                              style={{
                                borderRadius: 10,
                              }}
                            />
                          </span>
                          <article>
                            <label>{item.name}</label>
                            <p className="lnd-tag">{item.catalogName}</p>
                            <ul className="lnd-view">
                              <li>
                                <img src="/theme/img/icon-calenda.svg" />{" "}
                                {moment(item.createdTime).format("DD/MM/YYYY")}
                              </li>
                              {/* <li>
                                  <img src="/theme/img/icon-view.svg" /> 1000
                                </li>
                                <li>
                                  <img src="/theme/img/icon-comment.svg" /> 10
                                </li> */}
                            </ul>
                          </article>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              <LastestComments data={comments} />

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DocumentDetail;
