import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import LoadingSpinner from "src/components/loading-spinner";
import ENV from "src/config/ENV";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { saveSetting } from "src/store/slices/moduleSlice";
import InteresetCatalogs from "src/components/interest-catalogs";
import LastestComments from "src/components/comments/lastest";

import moment from "moment";
import {
  DocumentFieldCatalogApi,
  DocumentSubjectCatalogApi,
} from "src/api/catalog-api";

function FeatureNews({
  loading,
  data,
  firstNews,
  rightNews,
  otherNews,
  comments,
  profile,
}) {
  const [modules, setModules] = useCookies(["modules"]);
  const [subjectCatalogs, setSubjectCatalogs] = useState([]);
  const [fieldCatalogs, setFieldCatalogs] = useState([]);

  const history = useHistory();
  const dispatch = useDispatch();

  const moveTop = (module) => {
    let currentConfig = modules.modules;
    const index = currentConfig.indexOf(module);
    currentConfig.splice(index, 1);
    currentConfig[0] = module;
    setModules("modules", JSON.stringify(currentConfig));
    dispatch(saveSetting(currentConfig));
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (profile && profile.notificationDocumentContentFromFields) {
      return;
    }

    DocumentSubjectCatalogApi.searches(null).then((response) => {
      setSubjectCatalogs(response.data.data);
    });

    DocumentFieldCatalogApi.searches(null).then((response) => {
      setFieldCatalogs(response.data.data);
    });
  }, [profile]);

  return (
    <>
      <LoadingSpinner active={loading}>
        <div className="main-news">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                {modules.modules && modules.modules[0] != "feature-news" && (
                  <a
                    style={{ cursor: "pointer" }}
                    className="d-block mb-3 upto"
                    onClick={() => moveTop("feature-news")}
                  >
                    <img src="/theme/img/icon-upto.svg" />
                  </a>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                {data && (
                  <div className="mn-content">
                    {firstNews && (
                      <>
                        <Link
                          to={`/news/${firstNews.id}`}
                          className="mnc-big-news"
                        >
                          <span className="big-img">
                            <img
                              src={`${ENV.baseFileImageUrl}${firstNews.thumbnailUrl}`}
                            />
                          </span>
                          <div className="mnc-bn-title">
                            <span className="bn-tag">Tin mới</span>
                            <label style={{ cursor: "pointer" }}>
                              {firstNews.name}
                            </label>
                            <p className="list-tag">{firstNews.catalogName}</p>
                            <article>
                              <span>{firstNews.shortContent}</span>
                              <ul>
                                <li>
                                  <img src="/theme/img/icon-calenda.svg" />{" "}
                                  {moment(firstNews.createdTime).format(
                                    "DD/MM/YYYY"
                                  )}
                                </li>
                                {/* <li>
                                <img src="/theme/img/icon-view.svg" /> 1000
                              </li>
                              <li>
                                <img src="/theme/img/icon-comment.svg" /> 10
                              </li> */}
                              </ul>
                            </article>
                          </div>
                        </Link>
                        {otherNews && (
                          <>
                            <ul className="mnc-list-news mln-desktop">
                              {otherNews.map((item) => (
                                <li key={`mnc-list-news-${item.id}`}>
                                  <Link
                                    to={`/news/${item.id}`}
                                    className="ln-detail"
                                  >
                                    <span>
                                      <img
                                        src={
                                          item.thumbnailUrl != ""
                                            ? `${ENV.baseFileImageUrl}${item.thumbnailUrl}`
                                            : "/theme/img/no-img-1.jpg"
                                        }
                                        style={{
                                          width: 118,
                                          borderRadius: 10,
                                        }}
                                      />
                                    </span>
                                    <article>
                                      <label>{item.name}</label>
                                      <p className="lnd-tag color-9B9B9B">
                                        {item.catalogName}
                                      </p>
                                      <ul className="lnd-view">
                                        <li>
                                          <img src="/theme/img/icon-calenda.svg" />{" "}
                                          {moment(item.createdTime).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </li>
                                        {/* <li>
                                        <img src="/theme/img/icon-view.svg" />{" "}
                                        1000
                                      </li>
                                      <li>
                                        <img src="/theme/img/icon-comment.svg" />{" "}
                                        10
                                      </li> */}
                                      </ul>
                                    </article>
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </>
                        )}
                      </>
                    )}
                    {otherNews && (
                      <>
                        <ul className="mnc-list-news owl-carousel owl-theme mln-mobile">
                          {otherNews.map((item) => (
                            <li key={`mnc-list-news-other-${item.id}`}>
                              <Link
                                to={`/news/${item.id}`}
                                className="ln-detail"
                              >
                                <span>
                                  <img
                                    src={
                                      item.thumbnailUrl != ""
                                        ? `${ENV.baseFileImageUrl}${item.thumbnailUrl}`
                                        : "/theme/img/no-img-1.jpg"
                                    }
                                    style={{
                                      borderRadius: 10,
                                    }}
                                  />
                                </span>
                                <article>
                                  <label>{item.name}</label>
                                  <p className="lnd-tag color-9B9B9B">
                                    {item.catalogName}
                                  </p>
                                  <ul className="lnd-view">
                                    <li>
                                      <img src="/theme/img/icon-calenda.svg" />
                                      {moment(item.createdTime).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </li>
                                    {/* <li>
                                      <img src="/theme/img/icon-view.svg" />{" "}
                                      1000
                                    </li>
                                    <li>
                                      <img src="/theme/img/icon-comment.svg" />{" "}
                                      10
                                    </li> */}
                                  </ul>
                                </article>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </div>
                )}
              </div>
              <div className="col-md-4">
                <InteresetCatalogs
                  profile={profile}
                  subjectCatalogs={subjectCatalogs}
                  fieldCatalogs={fieldCatalogs}
                />

                {/* Tin nổi bật */}
                {rightNews && (
                  <div className="box-news bn-news-hot">
                    <label>Tin nổi bật</label>
                    <ul>
                      {rightNews.map((item) => (
                        <li key={`hot-news-${item.id}`}>
                          <Link to={`/news/${item.id}`} className="ln-detail">
                            <span>
                              <img
                                src={
                                  item.thumbnailUrl != ""
                                    ? `${ENV.baseFileImageUrl}${item.thumbnailUrl}`
                                    : "/theme/img/no-img-1.jpg"
                                }
                                style={{
                                  borderRadius: 10,
                                }}
                              />
                            </span>
                            <article>
                              <label>{item.name}</label>
                              <p className="lnd-tag color-9B9B9B">
                                {item.catalogName}
                                {/* Lĩnh vực A<span></span>Chủ đề B<span></span>Tên
                              tình huống có thể dài tối đa 2 dòng */}
                              </p>
                              <ul className="lnd-view">
                                <li>
                                  <img src="/theme/img/icon-calenda.svg" />{" "}
                                  {moment(item.createdTime).format(
                                    "DD/MM/YYYY"
                                  )}
                                </li>
                                {/* <li>
                                  <img src="/theme/img/icon-view.svg" /> 1000
                                </li>
                                <li>
                                  <img src="/theme/img/icon-comment.svg" /> 10
                                </li> */}
                              </ul>
                            </article>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                <LastestComments data={comments} />
              </div>
            </div>
          </div>
        </div>
      </LoadingSpinner>
    </>
  );
}

export default FeatureNews;
