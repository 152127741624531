import React from "react";
import { Link } from "react-router-dom";

export default function InteresetCatalogs({
  fieldCatalogs,
  subjectCatalogs,
  profile,
}) {
  return (
    <div>
      {profile &&
        profile.notificationDocumentContentFromFields &&
        profile.notificationDocumentContentFromFields.filter(
          (x) => x.isActive == true
        ).length > 0 && (
          <>
            <div className="box-news bn-label-hot">
              <label>Lĩnh vực quan tâm nhất</label>
              <ul>
                {profile.notificationDocumentContentFromFields
                  .filter((x) => x.isActive == true)
                  .map((item) => (
                    <li key={`interest-field-${item.id}`}>
                      <Link to={`/contents/${item.id}/0`}>{item.name}</Link>
                    </li>
                  ))}
              </ul>
            </div>
          </>
        )}

      {(!profile || !profile.notificationDocumentContentFromFields) &&
        fieldCatalogs &&
        fieldCatalogs.filter((x) => x.isFeature == true).length > 0 && (
          <>
            <div className="box-news bn-label-hot">
              <label>Lĩnh vực quan tâm nhất</label>
              <ul>
                {fieldCatalogs
                  .filter((x) => x.isFeature == true)
                  .slice(0, 5)
                  .map((item) => (
                    <li key={`interest-field-${item.id}`}>
                      <Link to={`/contents/${item.id}/0`}>{item.name}</Link>
                    </li>
                  ))}
              </ul>
            </div>
          </>
        )}

      {profile &&
        profile.notificationDocumentContentFromSubjects &&
        profile.notificationDocumentContentFromSubjects.filter(
          (x) => x.isActive == true
        ).length > 0 && (
          <>
            <div className="box-news bn-label-hot">
              <label>Chủ đề quan tâm nhất</label>
              <ul>
                {profile.notificationDocumentContentFromSubjects
                  .filter((x) => x.isActive == true)
                  .map((item) => (
                    <li key={`interest-catalog-${item.id}`}>
                      <Link to={`/contents/0/${item.id}`}>{item.name}</Link>
                    </li>
                  ))}
              </ul>
            </div>
          </>
        )}

      {(!profile || !profile.notificationDocumentContentFromSubjects) &&
        subjectCatalogs &&
        subjectCatalogs.filter((x) => x.isFeature == true).length > 0 && (
          <>
            <div className="box-news bn-label-hot">
              <label>Chủ đề quan tâm nhất</label>
              <ul>
                {subjectCatalogs
                  .filter((x) => x.isFeature == true)
                  .slice(0, 5)
                  .map((item) => (
                    <li key={`interest-field-${item.id}`}>
                      <Link to={`/contents/0/${item.id}`}>{item.name}</Link>
                    </li>
                  ))}
              </ul>
            </div>
          </>
        )}
    </div>
  );
}
