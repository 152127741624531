import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import UserApi from "src/api/user-api";
import moment from "moment";

import { Button, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";

function UserProfile() {
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    new UserApi().me().then((response) => {
      setProfile(response.data);
    });
  }, []);

  return (
    <>
      <div className="main-news" style={{ minHeight: 500, paddingTop: 50 }}>
        <div
          className="container"
          style={{ boxShadow: "0px 4px 16px #0000001f" }}
        >
          {profile && (
            <table className="table table-user-information">
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <br />
                    <h5>Thông tin tài khoản</h5>
                  </td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    <Typography color="text.secondary" gutterBottom>
                      Họ tên
                    </Typography>
                  </td>
                  <td>
                    <Typography component="div">{profile.fullName}</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography color="text.secondary" gutterBottom>
                      Email
                    </Typography>
                  </td>
                  <td>
                    <Typography component="div">{profile.email}</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography color="text.secondary" gutterBottom>
                      Ngày tham gia
                    </Typography>
                  </td>
                  <td>
                    <Typography component="div">
                      {moment(profile.createdTime).format("DD/MM/YYYY hh:mm")}
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <Button
                      variant="contained"
                      component={Link}
                      to="/usersetting"
                    >
                      Cấu hình tài khoản
                    </Button>
                    &nbsp; &nbsp;
                    <Button variant="contained" color="error">
                      Xóa tài khoản
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
}

export default UserProfile;
