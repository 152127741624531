import axios from 'axios';

import ApiBase from './api-base';

export default class ContentApi extends ApiBase {
  constructor() {
    super('content');
  }

  searches2 = (request: any) => {
    const url = `${this.baseApiUrl}/searches2`;
    const response = axios.post(url, request);
    return response;
  };
}
