import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import React, { useEffect, useState } from "react";
import ENV from "src/config/ENV";
import { useCookies } from "react-cookie";
import OwlCarousel from "react-owl-carousel";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CommentApi from "src/api/comment-api";
import HomeApi from "src/api/home-api";
import UserProfileApi from "src/api/user-profile-api";
import Shortcuts from "src/components/home/shortcuts";
import { selectedAccount } from "src/store/slices/accountSlice";
import moment from "moment";
import LoadingSpinner from "src/components/loading-spinner";
import KeywordApi from "src/api/keyword-api";
import Consultants from "./views/consultants";
import FeatureDocumentContents from "./views/feature-document-contents";
import InterestDocumentContents from "./views/interesting-document-contents";
import FeatureNews from "./views/feature-news";
import MyDocumentContents from "./views/my-document-content";

import { setting } from "src/store/slices/moduleSlice";

function Home() {
  const [data, setData] = useState(null);
  const [comments, setComments] = useState(null);
  const [profile, setProfile] = useState(null);
  const [firstNews, setFirstNews] = useState(null);
  const [loading, setLoading] = useState(false);
  const [suggestContents, setSuggestContents] = useState(null);
  const [loadingSuggest, setLoadingSuggest] = useState(false);
  const [rightNews, setRightNews] = useState(null);
  const [otherNews, setOtherNews] = useState(null);
  const [keywords, setKeywords] = useState(null);
  const [userViewSubjectIds, setUserViewSubjectIds] = useCookies([
    "userViewSubjectIds",
  ]);
  const [userViewFieldIds, setUserViewFieldIds] = useCookies([
    "userViewFieldIds",
  ]);
  const [modules, setModules] = useCookies(["modules"]);
  const currentUser = useSelector(selectedAccount);
  const currentSetting = useSelector(setting);
  const [modulesConfig, setModulesConfig] = useState(null);
  const [myDocumentContents, setMyDocumentContents] = useState(null);
  const [loadingMyDocumentContents, setLoadingMyDocumentContents] =
    useState(false);

  const initData = () => {
    setLoading(true);

    new HomeApi().index().then((response) => {
      if (response.data.news && response.data.news.length > 0) {
        setFirstNews(response.data.news[0]);
        let otherNews = response.data.news;
        const centerNewsCount = 5;
        otherNews.splice(0, 1);
        if (otherNews.length >= centerNewsCount) {
          setRightNews(otherNews.splice(centerNewsCount - 1, otherNews.length));
        }
        setOtherNews(otherNews);
      }
      setData(response.data);
      setLoading(false);
    });

    new CommentApi().getLatest().then((response) => {
      setComments(response.data.data);
    });

    setLoadingSuggest(true);
    new HomeApi()
      .recommendations(
        userViewSubjectIds.userViewSubjectIds ?? 0,
        userViewFieldIds.userViewFieldIds ?? 0
      )
      .then((response) => {
        setSuggestContents(response.data);
        setLoadingSuggest(false);
      });

    setLoadingMyDocumentContents(true);
    new HomeApi().getMyDocumentContents().then((response) => {
      setMyDocumentContents(response.data);
      setLoadingMyDocumentContents(false);
    });
  };

  useEffect(() => {
    if (currentUser && currentUser.id) {
      const api = new UserProfileApi();
      api.me().then((response) => {
        setProfile(response.data.value);
      });
    }

    initData();
  }, [currentUser]);

  useEffect(() => {
    const currentConfig = modules.modules ?? [
      "feature-news",
      "feature-document-contents",
      "interest-document-contents",
      "consultants",
    ];
    setModulesConfig(currentConfig);
  }, []);

  useEffect(() => {
    const currentConfig = modules.modules ?? [
      "feature-news",
      "feature-document-contents",
      "interest-document-contents",
      "consultants",
    ];
    setModulesConfig(currentConfig);
  }, [currentSetting]);

  const loadModule = () => {
    if (modulesConfig) {
      switch (modulesConfig[0]) {
        case "feature-document-contents":
          return <FeatureDocumentContents loading={loading} data={data} />;
        case "interest-document-contents":
          return (
            <InterestDocumentContents
              loading={loadingSuggest}
              data={suggestContents}
            />
          );
        case "consultants":
          return <Consultants />;
        case "feature-news":
          return (
            <FeatureNews
              data={data}
              loading={loading}
              otherNews={otherNews}
              comments={comments}
              rightNews={rightNews}
              firstNews={firstNews}
              profile={profile}
            />
          );
        default:
          return <></>;
      }
    }
  };

  return (
    <>
      <Shortcuts />

      {currentUser && currentUser.id && (
        <MyDocumentContents
          loading={loadingMyDocumentContents}
          data={myDocumentContents}
        />
      )}

      {loadModule()}

      <div className="main-banner" style={{ marginTop: 20 }}>
        <div className="container">
          {/* <a href="" className="d-block mb-3 upto">
            <img src="/theme/img/icon-upto.svg" />
          </a> */}
          <a href="" className="img-banner">
            <img src="/theme/img/banner.png" />
          </a>
        </div>
      </div>

      {(!modulesConfig || modulesConfig[0] != "feature-news") && (
        <FeatureNews
          data={data}
          loading={loading}
          firstNews={firstNews}
          otherNews={otherNews}
          comments={comments}
          rightNews={rightNews}
          profile={profile}
        />
      )}

      {(!modulesConfig || modulesConfig[0] != "feature-document-contents") && (
        <FeatureDocumentContents loading={loading} data={data} />
      )}

      {(!modulesConfig || modulesConfig[0] != "interest-document-contents") && (
        <InterestDocumentContents
          loading={loadingSuggest}
          data={suggestContents}
        />
      )}

      {(!modulesConfig || modulesConfig[0] != "consultants") && <Consultants />}

      <div className="main-service">
        <div className="container">
          {/* <a href="" className="d-block mb-3 upto">
            <img src="/theme/img/icon-upto.svg" />
          </a> */}
          <div className="main-title text-center">
            <h2>Gói dịch vụ</h2>
          </div>
          <OwlCarousel
            className="owl-carousel owl-theme service-carousel"
            loop
            margin={10}
            nav
            responsive={{
              0: {
                items: 1,
              },
              600: {
                items: 3,
              },
            }}
          >
            <a href="">
              <img src="/theme/img/service1.png" />
            </a>
            <a href="">
              <img src="/theme/img/service2.png" />
            </a>
            <a href="">
              <img src="/theme/img/service1.png" />
            </a>
            <a href="">
              <img src="/theme/img/service2.png" />
            </a>
            <a href="">
              <img src="/theme/img/service1.png" />
            </a>
            <a href="">
              <img src="/theme/img/service2.png" />
            </a>
          </OwlCarousel>
        </div>
      </div>
    </>
  );
}

export default Home;
