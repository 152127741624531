import axios from 'axios';
import Catalog from 'src/interfaces/Catalog';

import { CatalogType } from '../enums/CatalogType';
import ApiBase from './api-base';

class CatalogApi extends ApiBase {
  type: CatalogType;
  url: string;

  constructor(catalogType: CatalogType) {
    super('');

    this.url = '';
    this.type = catalogType;

    if (catalogType === CatalogType.DOCUMENT_FIELD_CATALOG) {
      this.baseApiUrl += 'documentfieldcatalog';
    }

    if (catalogType === CatalogType.DOCUMENT_ISSUER_CATALOG) {
      this.baseApiUrl += 'DocumentIssuerCatalog';
    }

    if (catalogType === CatalogType.DOCUMENT_CONTENT_CATALOG) {
      this.baseApiUrl += 'DocumentContentCatalog';
    }

    if (catalogType === CatalogType.DOCUMENT_TYPE_CATALOG) {
      this.baseApiUrl += 'DocumentTypeCatalog';
    }

    if (catalogType === CatalogType.DOCUMENT_SIGNER_CATALOG) {
      this.baseApiUrl += 'DocumentSignerCatalog';
    }

    if (catalogType === CatalogType.DOCUMENT_SUBJECT_CATALOG) {
      this.baseApiUrl += 'DocumentSubjectCatalog';
    }

    if (catalogType === CatalogType.SIGNER_POSITION_CATALOG) {
      this.baseApiUrl += 'SignerPositionCatalog';
    }

    if (catalogType === CatalogType.DOCUMENT_GROUP_CATALOG) {
      this.baseApiUrl += 'DocumentGroupCatalog';
    }

    if (catalogType === CatalogType.NEWS_CATALOG) {
      this.baseApiUrl += 'newscatalog';
    }

    if (catalogType === CatalogType.CONTENT_TYPE) {
      this.baseApiUrl += 'contenttype';
    }

    if (catalogType === CatalogType.SERVICE_CATALOG) {
      this.baseApiUrl += 'servicecatalog';
    }

    if (catalogType === CatalogType.GOVERNMENT_AGENCY_CATALOG) {
      this.baseApiUrl += 'govermentagencycatalog';
    }

    if (catalogType === CatalogType.BUSINESS_LINE_CATALOG) {
      this.baseApiUrl += 'businesslinecatalog';
    }

    if (catalogType === CatalogType.REGION_CATALOG) {
      this.baseApiUrl += 'regioncatalog';
    }

    if (catalogType === CatalogType.QUESTION_CATALOG) {
      this.baseApiUrl += 'questioncatalog';
    }

    if (catalogType === CatalogType.DocumentContentGroupCatalog) {
      this.baseApiUrl += 'DocumentContentGroupCatalog';
    }

    if (catalogType === CatalogType.DocumentFolderCatalog) {
      this.baseApiUrl += 'DocumentFolderCatalog';
    }

    if (catalogType === CatalogType.DocumentStatusCatalog) {
      this.baseApiUrl += 'DocumentStatusCatalog';
    }

    if (catalogType === CatalogType.UserGroupCatalog) {
      this.baseApiUrl += 'UserGroupCatalog';
    }

    if (catalogType === CatalogType.MarketCatalog) {
      this.baseApiUrl += 'MarketCatalog';
    }
  }

  createCatalog = (): Catalog => {
    return { Id: 0, Name: '' };
  };

  getAll = () => {
    const url = `${this.baseApiUrl}/GetAll`;
    const response = axios.get(url);
    return response;
  };

  getAcl = () => {
    const url = `${this.baseApiUrl}/getAcl`;
    const response = axios.get(url);
    return response;
  };
}

export default CatalogApi;

export const DocumentFieldCatalogApi = new CatalogApi(
  CatalogType.DOCUMENT_FIELD_CATALOG
);

export const DocumentTypeCatalogApi = new CatalogApi(
  CatalogType.DOCUMENT_TYPE_CATALOG
);

export const DocumentSubjectCatalogApi = new CatalogApi(
  CatalogType.DOCUMENT_SUBJECT_CATALOG
);

export const DocumentIssuerCatalogApi = new CatalogApi(
  CatalogType.DOCUMENT_ISSUER_CATALOG
);

export const DocumentSignerCatalogApi = new CatalogApi(
  CatalogType.DOCUMENT_SIGNER_CATALOG
);

export const SignerPositionCatalogApi = new CatalogApi(
  CatalogType.SIGNER_POSITION_CATALOG
);

export const ServiceCatalogApi = new CatalogApi(
  CatalogType.SERVICE_CATALOG
);

export const QuestionCatalogApi = new CatalogApi(
  CatalogType.QUESTION_CATALOG
);

export const UserGroupCatalogApi = new CatalogApi(
  CatalogType.UserGroupCatalog
);

export const MarketCatalogApi = new CatalogApi(
  CatalogType.MarketCatalog
);

export const BusinessLineCatalogApi = new CatalogApi(
  CatalogType.BUSINESS_LINE_CATALOG
);

export const DocumentContentGroupCatalogApi = new CatalogApi(
  CatalogType.DocumentContentGroupCatalog
);

export const DocumentFolderCatalogApi = new CatalogApi(
  CatalogType.DocumentFolderCatalog
);

export const DocumentStatusCatalogApi = new CatalogApi(
  CatalogType.DocumentStatusCatalog
);