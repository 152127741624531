import axios from 'axios';

import ApiBase from './api-base';

export default class UserContentTagApi extends ApiBase {
  constructor() {
    super("usercontenttag");
  }

  delete = (item: any) => {
    const url = `${this.baseApiUrl}`;
    const response = axios.post(`${url}/delete`, item);
    return response;
  };
}
