import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import KeywordApi from "src/api/keyword-api";
import { Link, useHistory } from "react-router-dom";
import { saveSetting } from "src/store/slices/appSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectAccount, selectedAccount } from "src/store/slices/accountSlice";
import ShortcutApi from "src/api/shortcut-api";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import LoadingSpinner from "src/components/loading-spinner";
import OwlCarousel from "react-owl-carousel";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Shortcuts() {
  const [open, setOpen] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [shortOpened, setShortOpened] = useCookies(["shortOpened"]);
  const [keywords, setKeywords] = useState(null);
  const [savingShortcut, setSavingShortcut] = useState(false);
  const [shortcuts, setShortcuts] = useState(null);
  const dispatch = useDispatch();
  const currentUser = useSelector(selectedAccount);
  const [keyword, setKeyword] = useState(null);
  const [shortcutName, setShortcutName] = useState(null);
  const [shortcutLink, setShortcutLink] = useState(null);
  const history = useHistory();

  useEffect(() => {
    setShortOpened("shortOpened", open);
    dispatch(saveSetting({ isOpenFilter: open }));
  }, [open]);

  useEffect(() => {
    setOpen(shortOpened.shortOpened ?? true);

    new KeywordApi().searches().then((response) => {
      setKeywords(response.data.data);
    });

    new ShortcutApi().searches().then((response) => {
      setShortcuts(response.data.data);
    });
  }, []);

  const doSearch = () => {
    if (keyword && keyword != "") {
      history.push(`/search/${encodeURIComponent(keyword)}`);
    }
  };

  const handleClose = () => {
    setOpenAddDialog(false);
  };

  const addShortcut = () => {
    if (shortcutName == "" || shortcutLink == "") return;
    setSavingShortcut(true);
    new ShortcutApi()
      .post({ name: shortcutName, link: shortcutLink })
      .then((response) => {
        new ShortcutApi().searches().then((response) => {
          setShortcuts(response.data.data);
          setShortcutName("");
          setShortcutLink("");
          setSavingShortcut(false);
          setOpenAddDialog(false);
        });
      });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      doSearch();
    }
  };

  return (
    <>
      <ul className="nav nav-pills" id="pills-tab" role="tablist">
        <div className="tab-content m-auto" id="pills-tabContent">
          {open && (
            <div className="tab-pane fade show active" id="pills1">
              <div className="main-search" id="over_map">
                <div className="container">
                  <div className="ms-title">
                    <h1>Xin chào, Chúng tôi có thể giúp gì cho bạn?</h1>
                    <p>
                      Tìm tài liệu, đặt câu hỏi và kết nối với cộng đồng của
                      chúng tôi.
                    </p>
                  </div>
                  <div className="ms-search">
                    <div className="form-search-edit">
                      <input
                        type="text"
                        name=""
                        placeholder="Nhập từ khóa, ít nhất 3 ký tự..."
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setKeyword(e.target.value)}
                      />
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={doSearch}
                        className="icon-search"
                        src="/theme/img/icon-search.svg"
                      />
                    </div>
                    {keywords && (
                      <div className="search-tag">
                        <label>Phổ biến:</label>
                        <ul>
                          {keywords.map((item) => (
                            <li>
                              <Link
                                to={`/search/${encodeURIComponent(item.name)}`}
                              >
                                {item.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="ms-list ms-list-desktop">
                    <div class="msl-box">
                      {shortcuts &&
                        shortcuts.map((item) => (
                          <div className="mslb-detail">
                            <a href={`${item.link}`} target="_blank">
                              <span>
                                <img src={item.iconUrl} />
                              </span>
                              <p>{item.name}</p>
                            </a>
                          </div>
                        ))}
                      {currentUser && currentUser.id && (
                        <div className="mslb-detail">
                          <a
                            onClick={() => setOpenAddDialog(true)}
                            style={{ cursor: "pointer" }}
                          >
                            <span>
                              <img src="/theme/img/icon-plus.svg" />
                            </span>
                            <p>Thêm lối tắt</p>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="ms-list ms-list-mobile">
                    <div>
                      {shortcuts && (
                        <OwlCarousel
                          items={4}
                          className="msl-box owl-carousel owl-theme search-carousel"
                        >
                          {shortcuts &&
                            shortcuts.map((item) => (
                              <div className="mslb-detail">
                                <a href={`${item.link}`} target="_blank">
                                  <span>
                                    <img src={item.iconUrl} />
                                  </span>
                                  <p>{item.name}</p>
                                </a>
                              </div>
                            ))}
                          {currentUser && currentUser.id && (
                            <div className="mslb-detail">
                              <a
                                onClick={() => setOpenAddDialog(true)}
                                style={{ cursor: "pointer" }}
                              >
                                <span>
                                  <img src="/theme/img/icon-plus.svg" />
                                </span>
                                <p>Thêm lối tắt</p>
                              </a>
                            </div>
                          )}
                        </OwlCarousel>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <a
                className="btn-toggle"
                onClick={() => {
                  setOpen(!open);
                  window.scrollTo(0, 0);
                }}
              >
                <span>Thu gọn tìm kiếm </span>
                <img src="/theme/img/icon-arowup.svg" />
              </a>
            </div>
          )}
          {!open && (
            <div className="tab-pane fade active show" id="pills2">
              <a
                href="javascript:;"
                className="btn-toggle active"
                onClick={() => {
                  setOpen(!open);
                }}
              >
                <span>Mở rộng tìm kiếm </span>
                <img
                  style={{ transform: "rotate(180deg)" }}
                  src="/theme/img/icon-arowup.svg"
                />
              </a>
            </div>
          )}
        </div>
      </ul>

      <Dialog open={openAddDialog} onClose={handleClose}>
        <DialogTitle>Tạo mới lối tắt</DialogTitle>
        <DialogContent>
          <LoadingSpinner active={savingShortcut}>
            <div>
              <TextField
                autoFocus
                required
                margin="dense"
                name="name"
                value={shortcutName}
                onChange={(e) => setShortcutName(e.target.value)}
                label="Tên"
                type="text"
                fullWidth
                variant="standard"
              />
              <TextField
                required
                margin="dense"
                name="link"
                value={shortcutLink}
                onChange={(e) => setShortcutLink(e.target.value)}
                label="Liên kết"
                type="text"
                fullWidth
                variant="standard"
              />
            </div>
          </LoadingSpinner>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Hủy bỏ</Button>
          <Button
            type="button"
            onClick={addShortcut}
            variant="contained"
            color="primary"
          >
            Tạo mới
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
